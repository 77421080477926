import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../../Provider/AuthContext";
import {useHistory} from "react-router-dom";
import api from "../../../../config/api";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import {formatDate} from "../../../utils/helper";
import SendFundModal from "./SendFundModal";

const AllBudgets = () => {
    const [applicationState]          = useContext(AuthContext);
    const history                     = useHistory();
    const [state, setState]           = useState({budgets: []});
    const [search, setSearch]         = useState(null);
    const [ownerModal, setOwnerModal] = useState(false);

    const [toggleOwner, setToggleOwner] = useState(!ownerModal);

    const getAllBudgets = async () => {
        let res = await api.get('/api/budgets');
        res.data.forEach(data => {
            data.expirationDate = data ? data.expirationDate ? formatDate(data.expirationDate) : null : null;
        })
        return res.data;
    }

    useEffect(() => {
        getAllBudgets()
            .then((res) => {
                setState((prevState) => ({
                    ...prevState,
                    budgets: res
                }));
            })
            .catch()
    }, []);

    const onCreateBudget = (e) => {
        e.preventDefault();
        window.location.href = '/addbudget';
    }

    const onSendFund = (e) => {
        e.preventDefault();
        setOwnerModal(true);
    }

    const onClose = (modalOpen) => {
        setOwnerModal(modalOpen);
        window.location.reload();
    }

    const selectBudget = (e, budget) => {
        e.preventDefault();
        window.location.href = "/budgets/" + budget.id;
    }

    return (
        <div className="">
            <div className="row">
                <div className="col-12">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <a href="/dashboard">{applicationState.companyName ? applicationState.companyName : 'Home'}</a>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            <a href="/budgets">Budgets</a>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </div>
            </div>

            <div className="card">
                <div className="card-header bg-white">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <h2>Budgets</h2>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <button type={"button"} className="btn btn-success float-right" disabled={applicationState.role !== "ADMIN"} onClick={onCreateBudget}>Create Budget</button>
                            <button type={"button"} className="btn btn-light mr-2 float-right" disabled={applicationState.role !== "ADMIN"} onClick={onSendFund}>Send Funds</button>
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    <div className="row">
                        {state.budgets.map(budget => {
                            return (<div className="col-lg-4 col-md-6 col-sm-6" key={budget.id}>
                                <div className="card card-hover border" onClick={(event) => selectBudget(event, budget)}>
                                    <div className="card-header bg-white">
                                        <div className="row ml-2">
                                            <h3>{budget.name}</h3>
                                        </div>
                                        <div
                                            className="row ml-2">
                                            <span className={"badge badge-inverse"}>
                                                {budget.budgetCycle === 'ONETIME' ? 'Expires : ' + [budget.expirationDate ? budget.expirationDate : ''] : [budget.budgetCycle === 'RECURRING' ? 'Recurring' : '']}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {budget.spentAmount < budget.assignedAmount &&
                                        <div className="progress active my-2" style={{height: "20px"}}>
                                            <div className="progress-bar bg-success" role="progressbar" style={{width: ((budget.spentAmount / budget.availableAmount) * 100) + "%"}}
                                                 aria-valuenow={(budget.spentAmount / budget.availableAmount) * 100} aria-valuemin="0" aria-valuemax="100">
                                            </div>
                                            <div className="progress-bar bg-secondary" role="progressbar" style={{width: ((budget.assignedAmount / budget.availableAmount) * 100) + "%"}}
                                                 aria-valuenow={(budget.assignedAmount / budget.availableAmount) * 100} aria-valuemin="0" aria-valuemax="100">
                                            </div>
                                            <div className="progress-bar bg-cyan" role="progressbar"
                                                 style={{width: (((budget.availableAmount - (budget.spentAmount + budget.assignedAmount)) / budget.availableAmount) * 100) + "%"}}
                                                 aria-valuenow={((budget.availableAmount - (budget.spentAmount + budget.assignedAmount)) / budget.availableAmount) * 100} aria-valuemin="0"
                                                 aria-valuemax="100">
                                            </div>
                                        </div>
                                        }
                                        {budget.spentAmount >= budget.assignedAmount &&
                                        <div className="progress active my-2" style={{height: "20px"}}>
                                            <div className="progress-bar bg-secondary" role="progressbar" style={{width: ((budget.assignedAmount / budget.availableAmount) * 100) + "%"}}
                                                 aria-valuenow={(budget.assignedAmount / budget.availableAmount) * 100} aria-valuemin="0" aria-valuemax="100">
                                            </div>
                                            <div className="progress-bar bg-success" role="progressbar" style={{width: ((budget.spentAmount / budget.availableAmount) * 100) + "%"}}
                                                 aria-valuenow={(budget.spentAmount / budget.availableAmount) * 100} aria-valuemin="0" aria-valuemax="100">
                                            </div>
                                            <div className="progress-bar bg-cyan" role="progressbar"
                                                 style={{width: (((budget.availableAmount - (budget.spentAmount + budget.assignedAmount)) / budget.availableAmount) * 100) + "%"}}
                                                 aria-valuenow={((budget.availableAmount - (budget.spentAmount + budget.assignedAmount)) / budget.availableAmount) * 100} aria-valuemin="0"
                                                 aria-valuemax="100">
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col">
                                                <i className="fas fa-circle text-success"></i> Spent
                                            </div>
                                            <div className="col">
                                                <h5 className="text-success text-right">${budget.spentAmount}</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <i className="fas fa-circle text-secondary"></i> Assigned
                                            </div>
                                            <div className="col">
                                                <h5 className="text-secondary text-right">${budget.assignedAmount}</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <i className="fas fa-circle text-cyan"></i> Available
                                            </div>
                                            <div className="col">
                                                <h4 className="text-cyan text-right">${budget.availableAmount - (budget.spentAmount + budget.assignedAmount)}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        })}
                    </div>
                </div>
            </div>
            <SendFundModal isOpen={ownerModal} onClose={onClose} budgetId={""}/>
        </div>
    );
}

export default AllBudgets;
