import React, {Component} from "react";
import NumberFormat from "react-number-format";

export default class ReviewAndSubmit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyInfo_companyLegalName: props.data.companyInfo_companyLegalName,
            companyInfo_companyWebsite: props.data.companyInfo_companyWebsite,
            companyInfo_companyDescription: props.data.companyInfo_companyDescription,
            companyInfo_industry: props.data.companyInfo_industry,
            companyInfo_noOfFullTimeEmployee: props.data.companyInfo_noOfFullTimeEmployee,
            companyInfo_noOfPartTimeEmployee: props.data.companyInfo_noOfPartTimeEmployee,
            companyInfo_doesAnyoneDoInternationTravel: props.data.companyInfo_doesAnyoneDoInternationTravel,
            companyInfo_physicalAddress: props.data.companyInfo_physicalAddress,
            companyInfo_phone: props.data.companyInfo_phone,
            companyInfo_companyType: props.data.companyInfo_companyType,
            companyInfo_dateEstablished: props.data.companyInfo_dateEstablished,
            financialInfo_businessTaxId: props.data.financialInfo_businessTaxId,
            financialInfo_annualRevenue: props.data.financialInfo_annualRevenue,
            financialInfo_monthlyBusinessCardSpend: props.data.financialInfo_monthlyBusinessCardSpend,
            financialInfo_desiredLineOfCredit: props.data.financialInfo_desiredLineOfCredit,
            primaryContact_firstName: props.data.primaryContact_firstName,
            primaryContact_lastName: props.data.primaryContact_lastName,
            primaryContact_residentialAddress: props.data.primaryContact_residentialAddress,
            primaryContact_workEmail: props.data.primaryContact_workEmail,
            primaryContact_phoneNumber: props.data.primaryContact_phoneNumber,
            primaryContact_dateOfBirth: props.data.primaryContact_dateOfBirth,
            primaryContact_ss: props.data.primaryContact_ss,
            primaryContact_title: props.data.primaryContact_title,
            beneficialOwner_haveBeneficialOwner: props.data.beneficialOwner_haveBeneficialOwner,
            connectBankAccount_companyBankAccount: props.data.connectBankAccount_companyBankAccount,
            stepAt: props.data.stepAt
        };
    }


    render() {
        return (
            <div className="step step1 mt-5 ">
                <div className="row justify-content-md-center">
                    <div className="col col-lg-6">
                        <div className="">
                            <h4>Review And Submit</h4>
                            <div id="Form" className="form-horizontal mt-5">
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">
                                            Company's Legal Name
                                        </label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.companyInfo_companyLegalName = f;
                                                }}
                                                autoComplete="off"
                                                type="text"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.companyInfo_companyLegalName}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">Company Website</label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.companyInfo_companyWebsite = f;
                                                }}
                                                autoComplete="off"
                                                type="text"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.companyInfo_companyWebsite}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">Company Description</label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.companyInfo_companyDescription = f;
                                                }}
                                                autoComplete="off"
                                                type="text"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.companyInfo_companyDescription}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">Industry</label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.companyInfo_industry = f;
                                                }}
                                                autoComplete="off"
                                                type="text"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.companyInfo_industry}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">
                                            Number of Full Time Employee
                                        </label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.companyInfo_noOfFullTimeEmployee = f;
                                                }}
                                                autoComplete="off"
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.companyInfo_noOfFullTimeEmployee}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">
                                            Number of Part Time Employee
                                        </label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.companyInfo_noOfPartTimeEmployee = f;
                                                }}
                                                autoComplete="off"
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.companyInfo_noOfPartTimeEmployee}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">
                                            Does Anyone Do Internation Travel
                                        </label>
                                        <div>
                                            <select
                                                ref={(e) => {
                                                    this.companyInfo_doesAnyoneDoInternationTravel = e;
                                                }}
                                                autoComplete="off"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.companyInfo_doesAnyoneDoInternationTravel}
                                                readOnly={true}
                                            >
                                                <option value="">Please select</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">Physical Address</label>
                                        <div>
                                          <textarea
                                              ref={(f) => {
                                                  this.companyInfo_physicalAddress = f;
                                              }}
                                              autoComplete="off"
                                              rows="3"
                                              className="form-control"
                                              required
                                              defaultValue={this.state.companyInfo_physicalAddress}
                                              readOnly={true}
                                          />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">Phone</label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.companyInfo_phone = f;
                                                }}
                                                autoComplete="off"
                                                type="text"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.companyInfo_phone}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">Company Type</label>
                                        <div>
                                            <select
                                                ref={(e) => {
                                                    this.companyInfo_companyType = e;
                                                }}
                                                autoComplete="off"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.companyInfo_companyType}
                                                readOnly={true}
                                            >
                                                <option value="">Please select</option>
                                                <option value="Corporation">Corporation</option>
                                                <option value="LLC">LLC</option>
                                                <option value="Partnership">Partnership</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">Date Established</label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.companyInfo_dateEstablished = f;
                                                }}
                                                autoComplete="off"
                                                type="date"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.companyInfo_dateEstablished}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">Business Tax ID</label>
                                        <div>
                                            <NumberFormat
                                                ref={(f) => {
                                                    this.financialInfo_businessTaxId = f;
                                                }}
                                                format="##-#######"
                                                mask="_"
                                                className="form-control"
                                                defaultValue={this.state.financialInfo_businessTaxId}
                                                allowEmptyFormatting
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">Annual Revenue</label>
                                        <div>
                                            <NumberFormat
                                                ref={(f) => {
                                                    this.financialInfo_annualRevenue = f;
                                                }}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                className="form-control"
                                                defaultValue={this.state.financialInfo_annualRevenue}
                                                allowEmptyFormatting
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">
                                            Monthly Business Card Spend
                                        </label>
                                        <div>
                                            <NumberFormat
                                                ref={(f) => {
                                                    this.financialInfo_monthlyBusinessCardSpend = f;
                                                }}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                className="form-control"
                                                defaultValue={this.state.financialInfo_monthlyBusinessCardSpend}
                                                allowEmptyFormatting
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">
                                            Desired Line Of Credit
                                        </label>
                                        <div>
                                            <NumberFormat
                                                ref={(f) => {
                                                    this.financialInfo_desiredLineOfCredit = f;
                                                }}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                className="form-control"
                                                defaultValue={this.state.financialInfo_desiredLineOfCredit}
                                                allowEmptyFormatting
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">First Name</label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.primaryContact_firstName = f;
                                                }}
                                                autoComplete="off"
                                                type="text"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.primaryContact_firstName}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">Last Name</label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.primaryContact_lastName = f;
                                                }}
                                                autoComplete="off"
                                                type="text"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.primaryContact_lastName}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">
                                            Residential Address
                                        </label>
                                        <div>
                                        <textarea
                                            ref={(f) => {
                                                this.primaryContact_residentialAddress = f;
                                            }}
                                            autoComplete="off"
                                            rows="0"
                                            className="form-control"
                                            required
                                            defaultValue={this.state.primaryContact_residentialAddress}
                                            readOnly={true}
                                        />
                                        </div>
                                    </div>
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">
                                            Work Email
                                        </label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.primaryContact_workEmail = f;
                                                }}
                                                autoComplete="off"
                                                type="email"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.primaryContact_workEmail}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">
                                            Phone Number
                                        </label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.primaryContact_phoneNumber = f;
                                                }}
                                                autoComplete="off"
                                                type="text"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.primaryContact_phoneNumber}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">
                                            Date Of Birth
                                        </label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.primaryContact_dateOfBirth = f;
                                                }}
                                                autoComplete="off"
                                                type="date"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.primaryContact_dateOfBirth}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">SS #</label>
                                        <div>
                                            <NumberFormat
                                                ref={(f) => {
                                                    this.primaryContact_ss = f;
                                                }}
                                                format="#########"
                                                mask="_"
                                                className="form-control"
                                                defaultValue={this.state.primaryContact_ss}
                                                allowEmptyFormatting
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">Title</label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.primaryContact_title = f;
                                                }}
                                                autoComplete="off"
                                                type="text"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.primaryContact_title}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">Do you have a beneficial owner who own at least 25% of the company</label>
                                        <div>
                                            <select
                                                ref={(e) => {
                                                    this.beneficialOwner_haveBeneficialOwner = e;
                                                }}
                                                autoComplete="off"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.beneficialOwner_haveBeneficialOwner}
                                                readOnly={true}
                                            >
                                                <option value="">Please select</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">Bank Account</label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.connectBankAccount_companyBankAccount = f;
                                                }}
                                                autoComplete="off"
                                                type="text"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.connectBankAccount_companyBankAccount}
                                                key={this.state.connectBankAccount_companyBankAccount}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
