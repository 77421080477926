import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import BeneficialOwners from "./BeneficialOwners";
import CompanyInformation from "./CompanyInformation";
import ConnectBankAccount from "./ConnectBankAccount";
import FinancialInformation from "./FinancialInformation";
import PrimaryContact from "./PrimaryContact";
import ReviewAndSubmit from "./ReviewAndSubmit";
import StepZilla from "react-stepzilla";
import {Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row} from "reactstrap";
import api from "../../../../config/api";
import {useHistory} from "react-router-dom";
import AuthContext from "../../../Provider/AuthContext";
import {formatDate} from "../../../utils/helper";
import {toast} from "react-toastify";

const ApplicationFlow = () => {
    const [applicationState]  = useContext(AuthContext);
    // console.log(applicationState.isValid);
    const history             = useHistory();
    const [state, setState]   = useState({
        stepAt: 0,
        companyInfo_companyLegalName: null,
        companyInfo_companyWebsite: null,
        companyInfo_companyDescription: null,
        companyInfo_industry: null,
        companyInfo_noOfFullTimeEmployee: null,
        companyInfo_noOfPartTimeEmployee: null,
        companyInfo_doesAnyoneDoInternationTravel: null,
        companyInfo_physicalAddress: null,
        companyInfo_phone: null,
        companyInfo_companyType: null,
        companyInfo_dateEstablished: null,
        financialInfo_businessTaxId: null,
        financialInfo_annualRevenue: null,
        financialInfo_monthlyBusinessCardSpend: null,
        financialInfo_desiredLineOfCredit: null,
        primaryContact_firstName: null,
        primaryContact_lastName: null,
        primaryContact_residentialAddress: null,
        primaryContact_workEmail: null,
        primaryContact_phoneNumber: null,
        primaryContact_dateOfBirth: null,
        primaryContact_ss: null,
        primaryContact_title: null,
        beneficialOwner_haveBeneficialOwner: null,
        connectBankAccount_companyBankAccount: null,
        isFetch: false,
        isStepFetch: false
    });
    const [stepAt, setStepAt] = useState(state.stepAt);


    const fetchStepWizardData = async () => {
        let res                              = await api.get('/api/registration/applicationflowdetails');
        res.data.companyInfo_dateEstablished = res.data ? res.data.companyInfo_dateEstablished ? formatDate(res.data.companyInfo_dateEstablished) : null : null;
        res.data.primaryContact_dateOfBirth  = res.data ? res.data.primaryContact_dateOfBirth ? formatDate(res.data.primaryContact_dateOfBirth) : null : null;
        return res.data;
    }

    const fetchStepData = async () => {
        let res = await api.get('/api/registration/getstate');
        return res.data ? (res.data.stepAt + 1) : 0;
    }

    useEffect(() => {
        fetchStepWizardData()
            .then((res) => {
                setState((prevState) => ({
                    ...prevState,
                    ...res,
                    isFetch: true
                }));
                fetchStepData()
                    .then((res) => {
                        setState((prevState) => ({
                            ...prevState,
                            stepAt: res,
                            isStepFetch: true
                        }));
                        setStepAt(res);
                    })
                    .catch(

                    )
            })
            .catch(

            )
    }, []);


    const companyInfoRef   = useRef();
    const financialInfoRef = useRef();
    const contactInfoRef   = useRef();
    const benefactorInfo   = useRef();
    const bankInfo         = useRef();

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }

            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }

    const [width] = useWindowSize();
    let device;
    if(width >= 1200) {
        device = "Desktop-Large";
    } else if(width >= 992 && width < 1200) {
        device = "Desktop";
    } else if(width >= 768 && width < 992) {
        device = "Tablet";
    } else {
        device = "Mobile";
    }
    localStorage.setItem("stepAt", state.stepAt);

    const onNext = async (value) => {
        // db call for saving
        try {
            let resObj = await api.post(`/api/registration/${stepAt}`, value);
            if(resObj) {
                let step = Number(stepAt) + Number(1);
                await setStepAt(step);
                let res = await fetchStepWizardData();
                await setState((prevState) => ({
                    ...prevState,
                    ...res,
                    isFetch: true
                }));
                let response = await fetchStepData();
                await setState((prevState) => ({
                    ...prevState,
                    stepAt: response,
                    isStepFetch: true
                }));
                await localStorage.setItem("stepAt", (Number(stepAt) + 1).toString());
                toast.success(resObj.data.message);
            }
        } catch(e) {
            let res = await fetchStepWizardData();
            await setState((prevState) => ({
                ...prevState,
                ...res,
                isFetch: true
            }));
            let response = await fetchStepData();
            await setState((prevState) => ({
                ...prevState,
                stepAt: response,
                isStepFetch: true
            }));
            if(e.response && e.response.status === 500 && e.response.status === 404) {
                toast.success(e.response.data.message);
            }
            await toast.success(e.message);
        }
    };

    const onSubmit = (value) => {
    };

    const onStepChange = async (step) => {
        await setStepAt(step);
        localStorage.setItem("stepAt", step);
        if(localStorage.getItem("stepAt") === "5") {
            window.location.reload(false);
        }
    };

    const clickPrev = async () => {
        let step = stepAt - 1;
        await setStepAt(step);
        let res = await fetchStepWizardData();
        setState((prevState) => ({
            ...prevState,
            ...res,
            isFetch: true
        }));
        document.getElementById('prev-button').click();
    };

    const clickNext = async () => {
        document.getElementById('next-button').click();
    };

    const accordionPrev = async () => {
        let step = stepAt - 1;
        await setStepAt(step);
        let res = await fetchStepWizardData();
        setState((prevState) => ({
            ...prevState,
            ...res,
            isFetch: true
        }));
        localStorage.setItem("stepAt", step.toString());
    };

    const accordionNext = async () => {
        let ref;
        if(stepAt === 0) {
            ref = companyInfoRef;
        } else if(stepAt === 1) {
            ref = financialInfoRef;
        } else if(stepAt === 2) {
            ref = contactInfoRef;
        } else if(stepAt === 3) {
            ref = benefactorInfo;
        } else if(stepAt === 4) {
            ref = bankInfo;
        }

        if(ref) {
            ref.current.validationCheck();
            if(ref.current.isValidated()) {
                let accordionStepsObj = {
                    companyInfo_companyLegalName: ref.current.props.data.companyInfo_companyLegalName,
                    companyInfo_companyWebsite: ref.current.props.data.companyInfo_companyWebsite,
                    companyInfo_companyDescription: ref.current.props.data.companyInfo_companyDescription,
                    companyInfo_industry: ref.current.props.data.companyInfo_industry,
                    companyInfo_noOfFullTimeEmployee: ref.current.props.data.companyInfo_noOfFullTimeEmployee,
                    companyInfo_noOfPartTimeEmployee: ref.current.props.data.companyInfo_noOfPartTimeEmployee,
                    companyInfo_doesAnyoneDoInternationTravel: ref.current.props.data.companyInfo_doesAnyoneDoInternationTravel,
                    companyInfo_physicalAddress: ref.current.props.data.companyInfo_physicalAddress,
                    companyInfo_phone: ref.current.props.data.companyInfo_phone,
                    companyInfo_companyType: ref.current.props.data.companyInfo_companyType,
                    companyInfo_dateEstablished: ref.current.props.data.companyInfo_dateEstablished,
                    financialInfo_businessTaxId: ref.current.props.data.financialInfo_businessTaxId,
                    financialInfo_annualRevenue: ref.current.props.data.financialInfo_annualRevenue,
                    financialInfo_monthlyBusinessCardSpend: ref.current.props.data.financialInfo_monthlyBusinessCardSpend,
                    financialInfo_desiredLineOfCredit: ref.current.props.data.financialInfo_desiredLineOfCredit,
                    primaryContact_firstName: ref.current.props.data.primaryContact_firstName,
                    primaryContact_lastName: ref.current.props.data.primaryContact_lastName,
                    primaryContact_residentialAddress: ref.current.props.data.primaryContact_residentialAddress,
                    primaryContact_workEmail: ref.current.props.data.primaryContact_workEmail,
                    primaryContact_phoneNumber: ref.current.props.data.primaryContact_phoneNumber,
                    primaryContact_dateOfBirth: ref.current.props.data.primaryContact_dateOfBirth,
                    primaryContact_ss: ref.current.props.data.primaryContact_ss,
                    primaryContact_title: ref.current.props.data.primaryContact_title,
                    beneficialOwner_haveBeneficialOwner: ref.current.props.data.beneficialOwner_haveBeneficialOwner,
                    connectBankAccount_companyBankAccount: ref.current.props.data.connectBankAccount_companyBankAccount
                }
                // db call to save
                try {
                    let resp = await api.post(`/api/registration/${stepAt}`, accordionStepsObj);
                    if(resp) {
                        console.log(resp.headers);
                        let step = Number(stepAt) + Number(1);
                        await setStepAt(step);
                        let res = await fetchStepWizardData();
                        setState((prevState) => ({
                            ...prevState,
                            ...res,
                            isFetch: true
                        }));
                        let response = await fetchStepData();
                        setState((prevState) => ({
                            ...prevState,
                            stepAt: response,
                            isStepFetch: true
                        }));
                        localStorage.setItem("stepAt", (Number(stepAt) + 1).toString());
                        await toast.success(resp.data.message);
                    }
                } catch(e) {
                    let res = await fetchStepWizardData();
                    setState((prevState) => ({
                        ...prevState,
                        ...res,
                        isFetch: true
                    }));
                    let response = await fetchStepData();
                    setState((prevState) => ({
                        ...prevState,
                        stepAt: response,
                        isStepFetch: true
                    }));
                    if(e.response && e.response.status === 500 && e.response.status === 404) {
                        toast.success(e.response.data.message);
                    }
                    toast.success(e.message);
                }
            }
        }
    };

    const handleSubmit = async (e) => {
        // db call to save
        try {
            let res = await api.post(`/api/registration/${state.stepAt}`);

            e.preventDefault();
            await toast.success(res.data.message);
            localStorage.clear();
            history.push("/login");
        } catch(e) {
            let res = await fetchStepWizardData();
            setState((prevState) => ({
                ...prevState,
                ...res,
                isFetch: true
            }));
            let response = await fetchStepData();
            setState((prevState) => ({
                ...prevState,
                stepAt: response,
                isStepFetch: true
            }));
            if(e.response && e.response.status === 500 && e.response.status === 404) {
                toast.success(e.response.data.message);
            }
            toast.success(e.message);
        }
    }

    const handleLogOut = (e) => {
        e.preventDefault();

        localStorage.clear();
        history.push("/login");
    }

    const steps = [
        {
            name: "Company Information",
            component: <CompanyInformation data={state} onNext={onNext}/>,
        },
        {
            name: "Financial Information",
            component: <FinancialInformation data={state} onNext={onNext}/>,
        },
        {
            name: "Primary Contact",
            component: <PrimaryContact data={state} onNext={onNext}/>,
        },
        {
            name: "Beneficial Owners",
            component: <BeneficialOwners data={state} onNext={onNext}/>,
        },
        {
            name: "Connect Bank Account",
            component: <ConnectBankAccount data={state} onNext={onNext}/>,
        },
        {
            name: "Review And Submit",
            component: <ReviewAndSubmit data={state} onSubmit={onSubmit}/>,
        }
    ];

    let renderElement;
    if(state.isFetch && state.isStepFetch) {

        if(["Desktop", "Desktop-Large"].includes(device)) {
            renderElement = <div className="page-content">
                <Card>
                    <CardHeader className="bg-white border-bottom">
                        <CardTitle>
                            <i className="fas fa-clipboard-check mr-2"/>Application Flow
                            <a href="" className="pull-right" onClick={handleLogOut}>Log out</a>
                        </CardTitle>
                    </CardHeader>

                    <CardBody className="border-bottom">
                        <div className="step-progress">
                            <StepZilla
                                steps={steps}
                                showNavigation={false}
                                showSteps={true}
                                startAtStep={Number(state.stepAt)}
                                backButtonText={"Back"}
                                prevBtnOnLastStep={false}
                                nextTextOnFinalActionStep={"Submit"}
                                nextButtonCls={
                                    "btn btn-next btn-primary btn-lg pull-left ml-25 mt-5"
                                }
                                backButtonCls={
                                    "btn btn-prev btn-light btn-lg pull-left ml-20 mt-5"
                                }
                                onStepChange={onStepChange}
                                dontValidate={false}
                            />
                        </div>
                    </CardBody>

                    <CardFooter className="bg-white">
                        <Row className="justify-content-md-center">
                            <Col lg="6" md="6" className="text-right">
                                {stepAt !== 0 && <button className="btn btn-next btn-outline-secondary btn-lg " onClick={clickPrev}>Back</button>}
                                {stepAt < 5 && <button className="btn btn-next btn-primary btn-lg ml-2" onClick={clickNext}>Next</button>}
                                {stepAt === 5 && <button className="btn btn-next btn-success btn-lg ml-2" onClick={handleSubmit}>Submit</button>}
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </div>;
        } else {
            renderElement = <div className="page-content">
                <Card>
                    <CardHeader className="bg-white border-bottom">
                        <CardTitle>
                            <i className="fas fa-clipboard-check mr-2"/>Application Flow
                        </CardTitle>
                    </CardHeader>

                    <CardBody className="border-bottom">
                        <div className="accordion">
                            <div className="border card">
                                <div className="card-header">Company Information</div>
                                <div className={"px-3 pb-3 collapse" + (stepAt === 0 ? "show" : null)}>
                                    <CompanyInformation data={state} onNext={onNext} ref={companyInfoRef}/>
                                    <Row>
                                        <Col className="text-right">
                                            <button className="btn btn-next btn-primary btn-lg ml-2" onClick={accordionNext}>Next</button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div className="border card">
                                <div className="card-header border-bottom">Financial Information</div>
                                <div className={"px-3 pb-3 collapse" + (stepAt === 1 ? "show" : null)}>
                                    <FinancialInformation data={state} onNext={onNext} ref={financialInfoRef}/>
                                    <Row>
                                        <Col className="text-right">
                                            <button className="btn btn-next btn-outline-secondary btn-lg" onClick={accordionPrev}>Back</button>
                                            <button className="btn btn-next btn-primary btn-lg ml-2" onClick={accordionNext}>Next</button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div className="border card">
                                <div className="card-header border-bottom">Primary Contact</div>
                                <div className={"px-3 pb-3 collapse" + (stepAt === 2 ? "show" : null)}>
                                    <PrimaryContact data={state} onNext={onNext} ref={contactInfoRef}/>
                                    <Row>
                                        <Col className="text-right">
                                            <button className="btn btn-next btn-outline-secondary btn-lg" onClick={accordionPrev}>Back</button>
                                            <button className="btn btn-next btn-primary btn-lg ml-2" onClick={accordionNext}>Next</button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div className="border card">
                                <div className="card-header border-bottom">Beneficial Owners</div>
                                <div className={"px-3 pb-3 collapse" + (stepAt === 3 ? "show" : null)}>
                                    <BeneficialOwners data={state} onNext={onNext} ref={benefactorInfo}/>
                                    <Row>
                                        <Col className="text-right">
                                            <button className="btn btn-next btn-outline-secondary btn-lg" onClick={accordionPrev}>Back</button>
                                            <button className="btn btn-next btn-primary btn-lg ml-2" onClick={accordionNext}>Next</button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div className="border card">
                                <div className="card-header border-bottom">Connect Bank Account</div>
                                <div className={"px-3 pb-3 collapse" + (stepAt === 4 ? "show" : null)}>
                                    <ConnectBankAccount data={state} onNext={onNext} ref={bankInfo}/>
                                    <Row>
                                        <Col className="text-right">
                                            <button className="btn btn-next btn-outline-secondary btn-lg" onClick={accordionPrev}>Back</button>
                                            <button className="btn btn-next btn-primary btn-lg ml-2" onClick={accordionNext}>Next</button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div className="border card">
                                <div className="card-header border-bottom">Review And Submit</div>
                                <div className={"px-3 pb-3 collapse" + (stepAt === 5 ? "show" : null)}>
                                    <ReviewAndSubmit data={state} onNext={onNext}/>
                                    <Row>
                                        <Col className="text-right">
                                            <button className="btn btn-next btn-outline-secondary btn-lg" onClick={accordionPrev}>Back</button>
                                            <button className="btn btn-next btn-success btn-lg ml-2" onClick={handleSubmit}>Submit</button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>;
        }
    } else {
        renderElement = (<div>
            loading...
        </div>);
    }

    return renderElement;
}

export default ApplicationFlow;
