import React, {useEffect, useState} from "react";
import {Input, FormGroup, Form, Row, Col, Button, Label} from "reactstrap";
import Countdown from "react-countdown";
import Alert from "reactstrap/lib/Alert";
import api from "../../../../config/api";
import {toast} from "react-toastify";

const OtpVerify = (props) => {
    localStorage.setItem("stage", "OtpVerify");
    const [state, setState]     = useState(props.data);
    const [status, setStatus]   = useState("IDLE");
    const [expired, setExpired] = useState(true);
    const [otp_ttl, setOtpTtl]  = useState(null);

    useEffect(() => {
        if(localStorage.getItem("otp_ttl")) {
            let ttl = Number(localStorage.getItem("otp_ttl"));
            if(ttl > Date.now()) {
                setOtpTtl(Number(ttl));
                setExpired(false);
            }
        }
    },[]);

    const setOtp = async (e) => {
        await setState({...state, otp: e.target.value});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.persist();

        try {
            setStatus("ACTIVE");
            let response = await api.post("/api/mail/validate-otp", {
                email: state.email,
                otp: state.otp
            })
            setStatus("IDLE");
            props.onSubmit({otp: state.otp, stage: "Password"});
            toast.success(response.data.message);
        } catch(error) {
            setStatus("IDLE");
            if(error.response.status === 500) {
                toast.error(error.response.data.message);
            } else if(error.response.status === 404) {
                toast.error(error.response.data.message);
            }
        }
    };

    const resend = async (e) => {
        e.preventDefault();

        try {
            await api.post("/api/mail/generate-otp", {
                email: state.email,
            });

            localStorage.setItem("otp_ttl", (Date.now() + process.env.REACT_APP_OTP_TTL_MINS * 60 * 1000).toString());
            window.location.reload();

            props.onSubmit({email: props.email, stage: "OtpVerify"});
        } catch(error) {
            console.log(error);
            return <Alert color="warning">{error.message}</Alert>;
        }
    };
    return (
    <div>
        <Row>
            <Col xs="12">
                <Form className="mt-3" id="loginform" onSubmit={handleSubmit}>
                    <FormGroup className="mb-3">
                        <Label for="otp">Enter OTP sent to {state.email}</Label>
                        <Input
                        type="text"
                        pattern="[0-9]{6}"
                        name="otp"
                        id="otp"
                        placeholder="Enter OTP"
                        bsSize="lg"
                        onChange={setOtp}
                        value={state.otp}
                        required
                        />
                    </FormGroup>
                    <Row className="mb-3 mt-3">
                        <Col xs="12">
                            <Button
                            color="primary"
                            size="lg"
                            className=""
                            type="submit"
                            disabled={status === 'ACTIVE'}
                            onClick={handleSubmit}
                            block>
                                Verify OTP
                                {status === 'ACTIVE' && <i className="ml-3 fa fa-spinner fa-spin"/>}
                            </Button>
                            {
                                status === 'ACTIVE' &&
                                <Col xs="12">
                                                <span className="text-info">
                                                    Checking OTP, please wait...
                                                </span>
                                </Col>
                            }
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
        <Row>
            {!expired ? <Col className="text-left">
                <div className="float-left">OTP Expires in</div>
                <div className="float-left ml-2 font-weight-bold">
                    <Countdown date={otp_ttl}
                               onComplete={setExpired}
                               renderer={(props) => (
                               <div>
                                   {props.minutes}:{props.seconds < 10 && "0"}
                                   {props.seconds}
                               </div>
                               )}/>
                </div>
            </Col> : <Col className="text-left">
                <div className="float-left">OTP Expired! </div>
            </Col>
            }
            <Col className="text-right">
                {
                    !expired && <span className="text-muted">Resend Otp</span>
                }
                {
                    expired && <a href="/" onClick={resend}>Resend Otp</a>
                }
            </Col>
        </Row>
    </div>
    );
}

export default OtpVerify;
