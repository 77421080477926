import React, {Component} from "react";

export default class BeneficialOwners extends Component {
    constructor(props) {
        super(props);

        this.state = {
            beneficialOwner_haveBeneficialOwner: props.data.beneficialOwner_haveBeneficialOwner,
            stepAt: props.data.stepAt,
        };

        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms

        this.validationCheck = this.validationCheck.bind(this);
        this.isValidated     = this.isValidated.bind(this);
    }

    isValidated() {
        const userInput        = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator
        let isDataValid        = false;

        // if full validation passes then save to store and pass as valid
        if(
            Object.keys(validateNewInput).every((k) => {
                return validateNewInput[k] === true;
            })
        ) {
            if(
                this.props.data.beneficialOwner_haveBeneficialOwner !== userInput.beneficialOwner_haveBeneficialOwner
            ) {// only update store of something changed
                this.props.onNext({
                    ...userInput, // use this to notify step4 that some changes took place and prompt the user to save again
                    stepAt: this.props.data.stepAt,
                }); // Update store here (this is just an example, in reality you will do it via redux or flux)
            }

            isDataValid = true;
        } else {
            // if anything fails then update the UI validation state but NOT the UI Data State
            this.setState(
                Object.assign(
                    userInput,
                    validateNewInput,
                    this._validationErrors(validateNewInput)
                )
            );
        }

        return isDataValid;
    }

    validationCheck() {
        if(!this._validateOnDemand) return;

        const userInput        = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator

        this.setState(
            Object.assign(
                userInput,
                validateNewInput,
                this._validationErrors(validateNewInput)
            )
        );
    }

    _validateData(data) {
        return {
            beneficialOwner_haveBeneficialOwnerVal: data.beneficialOwner_haveBeneficialOwner !== "",
        };
    }

    _validationErrors(val) {
        const errMsgs = {
            beneficialOwner_haveBeneficialOwnerValMsg: val.beneficialOwner_haveBeneficialOwnerVal
                ? ""
                : "Do you have a beneficial owner who own at least 25% of the company is required",
        };
        return errMsgs;
    }

    _grabUserInput() {
        return {
            beneficialOwner_haveBeneficialOwner: this.beneficialOwner_haveBeneficialOwner.value,
        };
    }

    render() {
        // explicit class assigning based on validation
        let notValidClasses = {};

        if(
            typeof this.state.beneficialOwner_haveBeneficialOwnerVal === "undefined" ||
            this.state.beneficialOwner_haveBeneficialOwnerVal
        ) {
            notValidClasses.beneficialOwner_haveBeneficialOwnerCls = "form-control";
        } else {
            notValidClasses.beneficialOwner_haveBeneficialOwnerCls       = "is-invalid form-control";
            notValidClasses.beneficialOwner_haveBeneficialOwnerValGrpCls = "text-danger";
        }

        return (
            <div className="step step1 mt-5 ">
                <div className="row justify-content-md-center">
                    <div className="col col-lg-6">
                        <div className="">
                            <h4>Setup Your Beneficial Owners</h4>
                            {/* <h6 className="card-subtitle">
                            To comply with regulation we collect this information to confirm
                            that you are a business that is legally incorporated.
                          </h6> */}
                            <form id="Form" className="form-horizontal mt-5">
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">Do you have a beneficial owner who own at least 25% of the company</label>
                                        <div>
                                            <select
                                                ref={(e) => {
                                                    this.beneficialOwner_haveBeneficialOwner = e;
                                                }}
                                                autoComplete="off"
                                                className={
                                                    notValidClasses.beneficialOwner_haveBeneficialOwnerCls
                                                }
                                                required
                                                defaultValue={this.state.beneficialOwner_haveBeneficialOwner}
                                                onBlur={this.validationCheck}
                                            >
                                                <option value="">Please select</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                            <div className={notValidClasses.beneficialOwner_haveBeneficialOwnerValGrpCls}>
                                                {this.state.beneficialOwner_haveBeneficialOwnerValMsg}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
