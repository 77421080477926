import React, {Component} from "react";
import NumberFormat from "react-number-format";

export default class FinancialInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            financialInfo_businessTaxId: props.data.financialInfo_businessTaxId,
            financialInfo_annualRevenue: props.data.financialInfo_annualRevenue,
            financialInfo_monthlyBusinessCardSpend: props.data.financialInfo_monthlyBusinessCardSpend,
            financialInfo_desiredLineOfCredit: props.data.financialInfo_desiredLineOfCredit,
            stepAt: props.data.stepAt,
        };

        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms

        this.validationCheck = this.validationCheck.bind(this);
        this.isValidated     = this.isValidated.bind(this);
    }

    isValidated() {
        const userInput        = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator
        let isDataValid        = false;
        // if full validation passes then save to store and pass as valid
        if(
            Object.keys(validateNewInput).every((k) => {
                return validateNewInput[k] === true;
            })
        ) {
            if(
                this.props.data.financialInfo_businessTaxId !== userInput.financialInfo_businessTaxId ||
                this.props.data.financialInfo_annualRevenue !== userInput.financialInfo_annualRevenue ||
                this.props.data.financialInfo_monthlyBusinessCardSpend !==
                userInput.financialInfo_monthlyBusinessCardSpend ||
                this.props.data.financialInfo_desiredLineOfCredit !== userInput.financialInfo_desiredLineOfCredit
            ) {
                // only update store of something changed
                this.props.onNext({
                    ...userInput, // use this to notify step4 that some changes took place and prompt the user to save again
                    stepAt: this.props.data.stepAt,
                }); // Update store here (this is just an example, in reality you will do it via redux or flux)
            }

            isDataValid = true;
        } else {
            // if anything fails then update the UI validation state but NOT the UI Data State
            this.setState(
                Object.assign(
                    userInput,
                    validateNewInput,
                    this._validationErrors(validateNewInput)
                )
            );
        }

        return isDataValid;
    }

    validationCheck() {
        if(!this._validateOnDemand) return;

        const userInput        = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator

        this.setState(
            Object.assign(
                userInput,
                validateNewInput,
                this._validationErrors(validateNewInput)
            )
        );
    }

    _validateData(data) {
        return {
            financialInfo_businessTaxIdVal: data.financialInfo_businessTaxId !== "" && data.financialInfo_businessTaxId.length === 9,
            financialInfo_annualRevenueVal: data.financialInfo_annualRevenue !== "",
            financialInfo_monthlyBusinessCardSpendVal: data.financialInfo_monthlyBusinessCardSpend !== "",
            financialInfo_desiredLineOfCreditVal: data.financialInfo_desiredLineOfCredit !== "",
        };
    }

    _validationErrors(val) {
        const errMsgs = {
            financialInfo_businessTaxIdValMsg: val.financialInfo_businessTaxIdVal
                ? ""
                : "Business Tax ID is required and should have 9 digits",
            financialInfo_annualRevenueValMsg: val.financialInfo_annualRevenueVal
                ? null
                : "Annual Revenue is required",
            financialInfo_monthlyBusinessCardSpendValMsg: val.financialInfo_monthlyBusinessCardSpendVal
                ? null
                : "Monthly Business Card Spend is required",
            financialInfo_desiredLineOfCreditValMsg: val.financialInfo_desiredLineOfCreditVal
                ? null
                : "Desired Line Of Credit is required",
        };
        return errMsgs;
    }

    _grabUserInput() {
        return {
            financialInfo_businessTaxId: this.financialInfo_businessTaxId.state.numAsString,
            financialInfo_annualRevenue: this.financialInfo_annualRevenue.state.numAsString,
            financialInfo_monthlyBusinessCardSpend: this.financialInfo_monthlyBusinessCardSpend.state.numAsString,
            financialInfo_desiredLineOfCredit: this.financialInfo_desiredLineOfCredit.state.numAsString,
        };
    }

    render() {
        // explicit class assigning based on validation
        let notValidClasses = {};

        if(
            typeof this.state.financialInfo_businessTaxIdVal === "undefined" ||
            this.state.financialInfo_businessTaxIdVal
        ) {
            notValidClasses.financialInfo_businessTaxIdCls = "form-control";
        } else {
            notValidClasses.financialInfo_businessTaxIdCls       = "is-invalid form-control";
            notValidClasses.financialInfo_businessTaxIdValGrpCls = "text-danger";
        }

        if(
            typeof this.state.financialInfo_annualRevenueVal === "undefined" ||
            this.state.financialInfo_annualRevenueVal
        ) {
            notValidClasses.financialInfo_annualRevenueCls = "form-control";
        } else {
            notValidClasses.financialInfo_annualRevenueCls       = "is-invalid form-control";
            notValidClasses.financialInfo_annualRevenueValGrpCls = "text-danger";
        }

        if(
            typeof this.state.financialInfo_monthlyBusinessCardSpendVal === "undefined" ||
            this.state.financialInfo_monthlyBusinessCardSpendVal
        ) {
            notValidClasses.financialInfo_monthlyBusinessCardSpendCls = "form-control";
        } else {
            notValidClasses.financialInfo_monthlyBusinessCardSpendCls       = "is-invalid form-control";
            notValidClasses.financialInfo_monthlyBusinessCardSpendValGrpCls = "text-danger";
        }

        if(
            typeof this.state.financialInfo_desiredLineOfCreditVal === "undefined" ||
            this.state.financialInfo_desiredLineOfCreditVal
        ) {
            notValidClasses.financialInfo_desiredLineOfCreditCls = "form-control";
        } else {
            notValidClasses.financialInfo_desiredLineOfCreditCls       = "is-invalid form-control";
            notValidClasses.financialInfo_desiredLineOfCreditValGrpCls = "text-danger";
        }

        return (
            <div className="step step1 mt-5 ">
                <div className="row justify-content-md-center">
                    <div className="col col-lg-6">
                        <div className="">
                            <h4>Setup Your Financial Information</h4>
                            {/* <h6 className="card-subtitle">
                To comply with regulation we collect this information to confirm
                that you are a business that is legally incorporated.
              </h6> */}
                            <form id="Form" className="form-horizontal mt-5">
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">Business Tax ID</label>
                                        <div>
                                            <NumberFormat
                                                ref={(f) => {
                                                    this.financialInfo_businessTaxId = f;
                                                }}
                                                format="##-#######"
                                                mask="_"
                                                className={notValidClasses.financialInfo_businessTaxIdCls}
                                                defaultValue={this.state.financialInfo_businessTaxId}
                                                onBlur={(values) => {
                                                    const {formattedValue, value} = values;
                                                    this.validationCheck()
                                                }}
                                                allowEmptyFormatting/>
                                            <div className={notValidClasses.financialInfo_businessTaxIdValGrpCls}>
                                                {this.state.financialInfo_businessTaxIdValMsg}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">Annual Revenue</label>
                                        <div>
                                            <NumberFormat
                                                ref={(f) => {
                                                    this.financialInfo_annualRevenue = f;
                                                }}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                className={notValidClasses.financialInfo_annualRevenueCls}
                                                defaultValue={this.state.financialInfo_annualRevenue}
                                                onBlur={(values) => {
                                                    const {formattedValue, value} = values;
                                                    this.validationCheck();
                                                }}
                                                allowEmptyFormatting={false}
                                                allowNegative={false}
                                                placeholder={"Annual Revenue"}/>
                                            <div className={notValidClasses.financialInfo_annualRevenueValGrpCls}>
                                                {this.state.financialInfo_annualRevenueValMsg}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">
                                            Monthly Business Card Spend
                                        </label>
                                        <div>
                                            <NumberFormat
                                                ref={(f) => {
                                                    this.financialInfo_monthlyBusinessCardSpend = f;
                                                }}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                className={notValidClasses.financialInfo_monthlyBusinessCardSpendCls}
                                                defaultValue={this.state.financialInfo_monthlyBusinessCardSpend}
                                                onBlur={(values) => {
                                                    const {formattedValue, value} = values;
                                                    this.validationCheck();
                                                }}
                                                allowEmptyFormatting={false}
                                                allowNegative={false}
                                                placeholder={"Monthly Business Card Spend"}/>
                                            <div className={notValidClasses.financialInfo_monthlyBusinessCardSpendValGrpCls}>
                                                {this.state.financialInfo_monthlyBusinessCardSpendValMsg}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">
                                            Desired Line Of Credit
                                        </label>
                                        <div>
                                            <NumberFormat
                                                ref={(f) => {
                                                    this.financialInfo_desiredLineOfCredit = f;
                                                }}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                className={notValidClasses.financialInfo_desiredLineOfCreditCls}
                                                defaultValue={this.state.financialInfo_desiredLineOfCredit}
                                                onBlur={(values) => {
                                                    const {formattedValue, value} = values;
                                                    this.validationCheck();
                                                }}
                                                allowEmptyFormatting={false}
                                                allowNegative={false}
                                                placeholder={"Desired Line Of Credit"}/>
                                            <div className={notValidClasses.financialInfo_desiredLineOfCreditValGrpCls}>
                                                {this.state.financialInfo_desiredLineOfCreditValMsg}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
