import React, {useEffect, useState} from "react";
import AuthContext from "./AuthContext";
import jwt_decode from 'jwt-decode';
import {useHistory} from "react-router-dom";

const AuthProvider = ({children, ...props}) => {
    const history = useHistory();
    const [inputState, setInputState] = useState({
        id: null,
        email: localStorage.getItem("email") || "",
        name: "",
        isFlowCompleted: localStorage.getItem("isFlowCompleted") || false,
        role: "",
        companyId: null,
        exp: null,
        companyName: null,
        activeDir: "ltr",
        activeThemeLayout: "vertical",
        activeTheme: "light",
        activeSidebarType: "full",
        activeLogoBg: "skin6",
        activeNavbarBg: "skin1",
        activeSidebarBg: "skin6",
        activeSidebarPos: "fixed",
        activeHeaderPos: "fixed",
        activeLayout: "full"
    });

    const callbacks = {
        handleChange: (event, key) => handleChange(event, key),
    };

    const handleChange = (event, key) => {
        setInputState((prevState) => ({
            ...prevState,
            [key]: event,
        }));
    };

    useEffect(() => {
        let user = jwt_decode(localStorage.getItem("token"));
        let now = new Date();
        let exp = new Date(user.exp * 1000);
        if(!user) {
            localStorage.clear();
            history.push("/login");
        }
        if(now.getTime() > exp.getTime()) {
            localStorage.clear();
            history.push("/login");
        }
        if(user.email !== localStorage.getItem("email")) {
            localStorage.clear();
            history.push("/login");
        }
        setInputState((prevState) => ({
            ...prevState,
            id:user.id,
            email: user.email,
            name: user.primaryContact_firstName + ' ' + user.primaryContact_lastName,
            role: user.role,
            isFlowCompleted: user.isFlowCompleted,
            companyId: user.companyId,
            exp: user.exp,
            companyName: user.companyInfo_companyLegalName
        }));
    }, [history])

    return (
    <AuthContext.Provider value={[inputState, callbacks]}>
        {children}
    </AuthContext.Provider>
    );
}

export default AuthProvider;
