import React from "react";
import {Button, Col, Form, FormGroup, Input, Row} from "reactstrap";
import api from "../../../../config/api";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";

const Password = (props) => {
    localStorage.setItem("stage", "Password");
    const history      = useHistory();
    const handleSubmit = async (e) => {
        e.preventDefault();
        e.persist();
        try {
            let response = await api.post("/api/auth/signup", {
                email: localStorage.getItem("email"),
                password: e.target.password.value
            })
            props.onSubmit({
                password: e.target.password.value,
                confirmPassword: e.target.confirmPassword.value,
                stage: "",
            });
            await toast.success(response.data.message);
            history.push("/login");
            localStorage.clear();
        } catch(e) {
            if(e.response && e.response.status === 500) {
                toast.error(e.response.data.message);
            }
            toast.error(e.message);
        }
    };

    const check = function() {
        if(
            document.getElementById("password").value !==
            document.getElementById("confirmPassword").value
        ) {
            document
                .getElementById("confirmPassword")
                .setCustomValidity("Passwords Don't Match");
        } else {
            document.getElementById("confirmPassword").setCustomValidity("");
        }
    };

    return (
        <div>
            <Row>
                <Col xs="12">
                    <Form className="mt-3" id="loginform" onSubmit={handleSubmit}>
                        <FormGroup className="mb-3">
                            <Input
                                type="password"
                                name="password"
                                id="password"
                                placeholder="Password"
                                value={props.password}
                                bsSize="lg"
                                required
                                onKeyUp={check}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Input
                                type="password"
                                name="confirmPassword"
                                id="confirmPassword"
                                placeholder="Confirm Password"
                                value={props.confirmPassword}
                                bsSize="lg"
                                required
                                onKeyUp={check}
                            />
                        </FormGroup>
                        <Row className="mb-3 mt-3">
                            <Col xs="12">
                                <Button
                                    color="primary"
                                    size="lg"
                                    className=""
                                    type="submit"
                                    block
                                >
                                    Sign Up
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}

export default Password;
