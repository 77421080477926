import React, {useState} from "react";
// import PropTypes from "prop-types";
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import api from "../../../../config/api";
import {toast} from "react-toastify";


const VerifyEmail = (props) => {
    localStorage.setItem("stage", "VerifyEmail");
    const [state, setState]   = useState(props.data);
    const [status, setStatus] = useState("IDLE");

    const setEmail = async (e) => {
        let str = e.target.value;
        await setState({...state, email: str});
        localStorage.setItem("email", str);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        e.persist();

        try {
            setStatus("ACTIVE");
            let response = await api.post("/api/mail/generate-otp", {
                email: e.target.email.value
            });
            setStatus("IDLE");

            localStorage.setItem("otp_ttl", (Date.now() + process.env.REACT_APP_OTP_TTL_MINS * 60 * 1000).toString());

            props.onSubmit({email: e.target.email.value, stage: "OtpVerify"});
            toast.success(response.data.message);
        } catch(error) {
            setStatus("IDLE");
            console.log(error.message);
            if(error.status === 500) {
                toast.error(error.message);
            }
        }
    };

    return (
        <div className="">
            <Row>
                <Col xs="12">
                    <Form className="mt-3" id="loginform" onSubmit={handleSubmit}>
                        <FormGroup className="mb-3">
                            <Label for="email">Email</Label>
                            <Input
                                className="form-control"
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Email"
                                bsSize="lg"
                                onChange={setEmail}
                                value={state.email}
                                required
                            />
                        </FormGroup>
                        <Row className="mb-3 mt-3">
                            <Col xs="12">
                                <Button
                                    color="primary"
                                    size="lg"
                                    // className="text-uppercase"
                                    disabled={status === 'ACTIVE'}
                                    type="submit"
                                    block
                                >
                                    Verify Email
                                    {status === 'ACTIVE' && <i className="ml-3 fa fa-spinner fa-spin"/>}
                                </Button>
                            </Col>
                            {
                                status === 'ACTIVE' &&
                                <Col xs="12">
                                    <span className="text-info">
                                        Sending OTP, please wait...
                                    </span>
                                </Col>
                            }
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default VerifyEmail;
