import React, {useEffect} from "react";
import {Route} from "react-router-dom";

const BlankLayout = (props) => {
	// useEffect(() => {
	// 	return () => {
	// 		window.removeEventListener("load", updateDimensions.bind(null));
	// 		window.removeEventListener("resize", updateDimensions.bind(null));
	// 	};
	// },[]);

	return (<Route component={props.view}/>);
};

export default BlankLayout;
