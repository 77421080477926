import React from 'react';


const Footer = () => {
    return (
        <footer className="footer text-center">
            {/*All Rights Reserved by Xtreme admin. Designed and Developed by{' '}*/}
            {/*<a href="https://wrappixel.com">WrapPixel</a>.*/}
            All Rights Reserved by Quarkie.
        </footer>
    );
}
export default Footer;
