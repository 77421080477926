import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import BlankLayout from "../Layout/BlankLayout";
import FullLayout from "../Layout/FullLayout";

import Login from "../views/Auth/Login/Login";
import SignUp from "../views/Auth/SignUp/SignUp";
import PrivateRouter from "./PrivateRouter";
import ApplicationFlow from "../views/Protected/ApplicationFlow/ApplicationFlow";
import AuthProvider from "../Provider/AuthProvider";
import Dashboard from "../views/Protected/Dashboard/Dashboard";
import AddPerson from "../views/Protected/People/AddPerson";
import AllPeople from "../views/Protected/People/AllPeople";
import AllBudgets from "../views/Protected/Budget/AllBudgets";
import AddBudget from "../views/Protected/Budget/AddBudget";
import ImportPeople from "../views/Protected/People/ImportPeople";
import BudgetDetail from "../views/Protected/Budget/BudgetDetail";
import AllCards from "../views/Protected/Card/AllCards";

const MainRouter = () => {
	return (
	<Router>
		<Switch>
			<Route exact path="/">
				<PrivateRouter/>
			</Route>
			<Route path="/signup" render={(props) => <BlankLayout {...props} view={SignUp}/>}/>
			<Route path="/login" render={(props) => <BlankLayout {...props} view={Login}/>}/>
			<AuthProvider>
				<Route path="/applicationflow" render={(props) => <BlankLayout {...props} view={ApplicationFlow}/>}/>
				<Route path="/dashboard" render={(props) => <FullLayout {...props} view={Dashboard}/>}/>
				<Route path="/people" render={(props) => <FullLayout {...props} view={AllPeople}/>}/>
				<Route path="/addperson" render={(props) => <BlankLayout {...props} view={AddPerson}/>}/>
				<Route path="/importpeople" render={(props) => <BlankLayout {...props} view={ImportPeople}/>}/>
				<Route exact path="/budgets/:id" render={(props) => <FullLayout {...props} view={BudgetDetail}/>}/>
				<Route exact path="/budgets" render={(props) => <FullLayout {...props} view={AllBudgets}/>}/>
				<Route path="/addbudget" render={(props) => <BlankLayout {...props} view={AddBudget}/>}/>
				<Route exact path="/cards" render={(props) => <FullLayout {...props} view={AllCards}/>}/>
			</AuthProvider>
		</Switch>
	</Router>
	);
};

export default MainRouter;
