import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import AuthContext from "../../../Provider/AuthContext";
import {CustomInput, FormGroup, Label} from "reactstrap";
import api from "../../../../config/api";
import {toast} from "react-toastify";
import importCsv from "../../../../assets/templates/import.csv";

const AddPerson = () => {
    const [applicationState]  = useContext(AuthContext);
    const history             = useHistory();
    const [state, setState]   = useState({
        email: '',
        role: 'MEMBER',
        companyId: null,
        primaryContact_firstName: '',
        primaryContact_lastName: '',
        primaryContact_workEmail: '',
        primaryContact_phoneNumber: '',
        primaryContact_dateOfBirth: '',
        isFlowCompleted: true,
        isActive: true,
        sendInvite: true
    });
    const [status, setStatus] = useState("IDLE");

    useEffect(() => {
        if(state.email === '' || state.role === '' || state.primaryContact_firstName === '' || state.primaryContact_lastName === '' || state.primaryContact_workEmail === '' || state.primaryContact_phoneNumber === '' || state.primaryContact_dateOfBirth === '') {
            setStatus("IDLE");
        } else {
            setStatus("ACTIVE");
        }
    }, [state]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let response = await api.post(`/api/people/add`, state);
            await history.push("/people");
            await toast.success(response.data.message);
        } catch(e) {
            console.log(e);
            if(e.response && e.response.status === 500) {
                toast.error(e.response.data.message);
            }
            toast.error(e.message);
        }
    }


    const onBlur = (event, key) => {
        let value = event.target.value;
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
        if(state.email && state.email !== '') {
            setState((prevState) => ({
                ...prevState,
                primaryContact_workEmail: state.email,
                companyId: applicationState.companyId
            }));
        }
    }

    const onCancel = (e) => {
        e.preventDefault();
        history.push("/people");
    }

    return (
        <div className="step step1 mt-5">
            <form onSubmit={handleSubmit}>
                <div className="row justify-content-md-center">
                    <div className="col col-lg-8 border-bottom">
                        <div className="row mx-2">
                            <div className="col-lg-8 col-md-8 col-sm-12">
                                <h1>Add person</h1>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                {applicationState.role !== "ADMIN" ?
                                <button type={"submit"} className="btn btn-success float-right" disabled={true} onClick={handleSubmit}>Add person</button>
                                    :
                                <button type={"submit"} className="btn btn-success float-right" disabled={status === 'IDLE'} onClick={handleSubmit}>Add person</button>}
                                <button type={"button"} className="btn btn-light mr-2 float-right" onClick={onCancel}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col col-lg-8">
                        <div className="row m-2">
                            <div className="col-lg-8 col-md-8 col-sm-12">
                                <span>Allergic to manual data entry? {
                                    applicationState.role !== "ADMIN"
                                        ? <Link to="/importpeople" onClick={ (event) => event.preventDefault() }>import people in link</Link>
                                        : <Link to="/importpeople">import people in link</Link>}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-md-center mx-2">
                    <div className="col col-lg-8 col-md-8 col-sm-12">
                        <div className="">
                            <div id="Form" className="form-horizontal mt-5">
                                <div className="row">
                                    <div className="col-lg-5 col-md-5 col-sm -12">
                                        <h3 className="mb-3">Personal Information</h3>
                                        <div className="mb-3">
                                            <Label for="firstName">First Name</Label>
                                            <input className="form-control"
                                                   type="text"
                                                   name="firstName"
                                                   placeholder="First Name"
                                                   defaultValue={state.primaryContact_firstName}
                                                   onBlur={(event) => onBlur(event, "primaryContact_firstName")}
                                                   required/>
                                        </div>
                                        <div className="mb-3">
                                            <Label for="lastName">Last Name</Label>
                                            <input className="form-control"
                                                   type="text"
                                                   name="lastName"
                                                   placeholder="Last Name"
                                                   defaultValue={state.primaryContact_lastName}
                                                   onBlur={(event) => onBlur(event, "primaryContact_lastName")}
                                                   required/>
                                        </div>

                                        {
                                            (state.primaryContact_firstName && state.primaryContact_lastName) &&
                                            <div className="mb-3">
                                                <Label for="name">Name On Card</Label>
                                                <span className="form-control">{state.primaryContact_firstName} {state.primaryContact_lastName}</span>
                                            </div>
                                        }


                                        <div className="mb-3">
                                            <Label for="email">Email</Label>
                                            <input
                                                className="form-control"
                                                type="email"
                                                name="email"
                                                id="email1"
                                                placeholder="Email"
                                                onBlur={(event) => onBlur(event, "email")}
                                                defaultValue={state.email}
                                                required
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <Label for="primaryContact_phoneNumber">Phone Number</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="primaryContact_phoneNumber"
                                                id="primaryContact_phoneNumber"
                                                placeholder="Phone Number"
                                                onBlur={(event) => onBlur(event, "primaryContact_phoneNumber")}
                                                defaultValue={state.primaryContact_phoneNumber}
                                                required
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <Label for="primaryContact_dateOfBirth">Date Of Birth</Label>
                                            <input
                                                className="form-control"
                                                type="date"
                                                name="primaryContact_dateOfBirth"
                                                id="primaryContact_dateOfBirth"
                                                placeholder="Date of birth"
                                                onBlur={(event) => onBlur(event, "primaryContact_dateOfBirth")}
                                                defaultValue={state.primaryContact_dateOfBirth}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-5 offset-lg-2 col-md-5 offset-md-2 col-sm-12">
                                        <h3 className="mb-3">Permissions</h3>
                                        <div className="mb-3">
                                            <Label for="role">Role</Label>
                                            <select
                                                autoComplete="off"
                                                className="form-control"
                                                name="role"
                                                id="role"
                                                required
                                                defaultValue={state.role}
                                                onBlur={(event) => onBlur(event, "role")}
                                            >
                                                <option value="">Please select</option>
                                                <option value="ADMIN">Admin</option>
                                                <option value="MEMBER">Member</option>
                                            </select>
                                        </div>

                                        <h3 className="mt-5">Creation Options</h3>
                                        <FormGroup className="mt-3">
                                            <CustomInput type="radio" className="mb-3" id="exampleCustomRadio3" name="customRadio" label="Send Invite" defaultChecked={state.sendInvite}
                                                         defaultValue={state.sendInvite}
                                                         onBlur={(event) => onBlur(event, "sendInvite")}/>
                                            <p className="text-secondary">You can send an invite at any time; many customers prefer to wait until the physical cards have arrived in the mail.</p>
                                            <CustomInput type="radio" className="mb-3" id="exampleCustomRadio4" name="customRadio" label="Order Physical Card" disabled={true}/>

                                            <p className="text-secondary">We recommend a card for everyone. You have total control of funds they can access.</p>
                                        </FormGroup>

                                        {/*<div className="mt-5">*/}
                                        {/*    <h5 className="text-secondary text-uppercase">Shipping Method</h5>*/}
                                        {/*    <CustomInput type="radio" className="mb-3" id="exampleCustomRadio3" name="customRadio" label="Send Invite" defaultChecked={state.sendInvite}*/}
                                        {/*                 defaultValue={state.sendInvite}*/}
                                        {/*                 onBlur={(event) => onBlur(event, "sendInvite")}/>*/}
                                        {/*    <CustomInput type="radio" className="mb-3" id="exampleCustomRadio3" name="customRadio" label="Send Invite" defaultChecked={state.sendInvite}*/}
                                        {/*                 defaultValue={state.sendInvite}*/}
                                        {/*                 onBlur={(event) => onBlur(event, "sendInvite")}/>*/}
                                        {/*    <CustomInput type="radio" className="mb-3" id="exampleCustomRadio3" name="customRadio" label="Send Invite" defaultChecked={state.sendInvite}*/}
                                        {/*                 defaultValue={state.sendInvite}*/}
                                        {/*                 onBlur={(event) => onBlur(event, "sendInvite")}/>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AddPerson;
