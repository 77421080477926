import React, {useContext} from 'react';
import AuthContext from "../../../Provider/AuthContext";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";

const Dashboard = () => {
    const [applicationState]  = useContext(AuthContext);
    return (
        <div className="">
            <div className="row">
                <div className="col-12">
                    <Breadcrumb>
                        <BreadcrumbItem active>
                            <a href="/dashboard">{applicationState.companyName ? applicationState.companyName : 'Home'}</a>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </div>
            </div>

        </div>
    );
}

export default Dashboard;
