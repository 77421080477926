import React, {useContext, useEffect, useState} from 'react';
import _ from "lodash";
import 'react-step-progress/dist/index.css';
import {Link, useHistory} from "react-router-dom";
import "../../../../assets/custom/left-and-right-modal.css";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import api from "../../../../config/api";
import UserAvatar from "react-user-avatar";
import NumberFormat from "react-number-format";
import Moment from 'react-moment';
import {toast} from "react-toastify";
import {formatDate} from "../../../utils/helper";
import AuthContext from "../../../Provider/AuthContext";

const AddBudget = () => {
    const [applicationState]          = useContext(AuthContext);
    const history = useHistory();

    const [step1, setStep1] = useState({
        name: '',
        budget: ''
    });

    const [step2, setStep2] = useState({
        cycle: '',
        hasExpiry: false,
        expiry: ''
    });


    const [step3, setStep3]                   = useState({
        owners: [],
        members: []
    });
    const [people, setPeople]                 = useState([]);
    const [peopleStatus, setPeopleStatus]     = useState({});
    const [filteredPeople, setFilteredPeople] = useState([]);
    const [ownerModal, setOwnerModal]         = useState(false);
    const [memberModal, setMemberModal]       = useState(false);

    const [step4, setStep4] = useState({control: "OWNER"});

    const [step, setStep] = useState(1);

    useEffect(() => {
        getPeople().then().catch();
    }, []);

    const handleSubmit1 = (e) => {
        e.preventDefault();
        setStep(step + 1);
    }

    const handleSubmit2 = (e) => {
        e.preventDefault();
        setStep(step + 1);
    }

    const toggleOwner = () => setOwnerModal(!ownerModal);

    const toggleMember = () => setMemberModal(!memberModal);

    const selectOwner = (person) => {
        let status        = peopleStatus;
        status[person.id] = 'OWNER';
        setPeopleStatus(status);

        setStep3({
            ...step3,
            owners: [...step3.owners, person]
        });
    }

    const unselectOwner = (person) => {
        let status        = peopleStatus;
        status[person.id] = null;
        setPeopleStatus(status);

        let owners = _.filter(step3.owners, (owner) => {
            return owner.id !== person.id
        })
        setStep3({
            ...step3,
            owners
        });
    }

    const selectMember = (person) => {
        let status        = peopleStatus;
        status[person.id] = 'MEMBER';
        setPeopleStatus(status);

        setStep3({
            ...step3,
            members: [...step3.members, person]
        });
    }

    const unselectMember = (person) => {
        let status        = peopleStatus;
        status[person.id] = null;
        setPeopleStatus(status);

        let members = _.filter(step3.members, (member) => {
            return member.id !== person.id
        })
        setStep3({
            ...step3,
            members
        });
    }

    const handleSubmit3 = (e) => {
        e.preventDefault();
        if(step3.owners.length > 0) {
            setStep(step + 1);
        } else {
            toast.warning("At least one owner is required");
        }
    }

    const handleSubmit4 = (e) => {
        e.preventDefault();
        setStep(step + 1);
    }

    const getPeople = async () => {
        let {data} = await api.get('/api/people/');
        let obj    = {...peopleStatus};

        data.forEach(d => {
            obj[d.id] = obj[d.id] || null;
        });

        setPeopleStatus(obj);
        setPeople(data);
        setFilteredPeople(data);
    }

    const searchPeople = async (search) => {
        if(search.length === 0) {
            setFilteredPeople(people);
        } else {
            let filtered = _.filter(people, (person) => {
                return person.primaryContact_firstName.indexOf(search) !== -1 || person.primaryContact_lastName.indexOf(search) !== -1
            });
            setFilteredPeople(filtered);
        }
    }

    const submit = async () => {
        try {
            let payload  = {
                name: step1.name,
                availableAmount: step1.budget,
                bufferAmount: 0,
                budgetCycle: step2.cycle,
                expirationDate: step2.hasExpiry ? step2.expiry : null,
                owners: _.map(step3.owners, 'id'),
                members: _.map(step3.members, 'id'),
                spendControl: step4.control
            }
            let response = await api.post('/api/budgets', payload);
            await history.push("/budgets");
            await toast.success(response.data.message);
        } catch(e) {
            console.dir(e);
            if(e.response && e.response.status === 500) {
                toast.error(e.response.data.message);
            }
            toast.error(e.message);
        }
    }

    const cancel = () => {
        history.push("/budgets");
    }

    return (
        <div className="step step1 mt-5">
            <div className="row justify-content-md-center">
                <div className="col-lg-10 col-md-10 col-sm-12">
                    <div className="row mx-2">
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <h1>Create new budget</h1>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 text-right">
                            <button type={"button"} className="btn btn-light mr-2" onClick={cancel}>Cancel</button>
                            {step === 1 && <button type={"button"} className="btn btn-dark" onClick={() => document.getElementById("formSubmit1").click()} disabled={applicationState.role != "ADMIN"}>Next</button>}
                            {step === 2 && <button type={"button"} className="btn btn-dark" onClick={() => document.getElementById("formSubmit2").click()} disabled={applicationState.role != "ADMIN"}>Next</button>}
                            {step === 3 && <button type={"button"} className="btn btn-dark" onClick={() => document.getElementById("formSubmit3").click()} disabled={applicationState.role != "ADMIN"}>Next</button>}
                            {step === 4 && <button type={"button"} className="btn btn-dark" onClick={() => document.getElementById("formSubmit4").click()} disabled={applicationState.role != "ADMIN"}>Next</button>}
                            {step === 5 && <button type={"button"} className="btn btn-success" onClick={submit} disabled={step === 4 ||  applicationState.role != "ADMIN"}>Submit</button>}
                        </div>
                    </div>
                    <div className="mx-2 mb-2 progress">
                        <div className="progress-bar bg-info" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{width: (step * 20) + '%'}}/>
                    </div>
                    {(step === 1) &&
                    <div className="row mt-4 mx-2">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h4>Let's get some basic information.</h4>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <form onSubmit={handleSubmit1} className="row">
                                <div className="col-12">
                                    <h5>Start of by giving your budget description.</h5>
                                    <small className={"text-muted"}>We recommend you set up your budget in Quarkie based on your company's departments.</small>
                                </div>

                                <div className="col-12 mt-4">
                                    <input type="text" className={"form-control form-control-lg"} placeholder={"Budget Name"} value={step1.name}
                                           onChange={(e) => setStep1({...step1, name: e.target.value})} required/>
                                </div>

                                <div className="col-12 mt-5">
                                    <h5>How much do you plan to spend from this budget?</h5>
                                    <small className={"text-muted"}>This is the maximum amount that can be spent from this budget. Adding buffer will allow spenders to exceed the limit if approved by
                                        a
                                        budget owner.</small>
                                </div>

                                <div className="col-12 mt-4">
                                    <NumberFormat thousandSeparator={true}
                                                  prefix={"$"}
                                                  className={"form-control form-control-lg"}
                                                  value={step1.budget}
                                                  onValueChange={(values) => {
                                                      const {value} = values;
                                                      setStep1({...step1, budget: value})
                                                  }}
                                                  allowEmptyFormatting={false}
                                                  allowNegative={false}
                                                  placeholder={"Budget"}
                                                  required/>
                                </div>

                                <button id="formSubmit1" className={"d-none"} type={"submit"}/>
                            </form>
                        </div>
                    </div>
                    }
                    {(step === 2) &&
                    <div className="row mt-4 mx-2">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h4>Tell us a little more about timeline of this budget.</h4>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <form onSubmit={handleSubmit2} className="row">
                                <div className="col-12">
                                    <h5>Budget Cycle</h5>
                                    <small className={"text-muted"}>You can set your budget to automatically replenish on a monthly basis.</small>
                                </div>

                                <div className="col-12 mt-4">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="cycleRadio" id="cycleRadio1" value="ONETIME" required
                                               checked={step2.cycle === 'ONETIME'} onChange={(e) => setStep2({...step2, cycle: e.target.value})}/>
                                        <label className="form-check-label" htmlFor="exampleRadios1">
                                            One Time
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="cycleRadio" id="cycleRadio2" value="RECURRING" required
                                               checked={step2.cycle === 'RECURRING'} onChange={(e) => setStep2({...step2, cycle: e.target.value})}/>
                                        <label className="form-check-label" htmlFor="exampleRadios2">
                                            Recurring
                                        </label>
                                    </div>
                                </div>

                                <div className="col-12 mt-5">
                                    <h5>Is this budget going to expire?</h5>
                                    <small className={"text-muted"}>You can set expiration date on a budget that will remove all funds from people and cards after the date you choose.</small>
                                </div>

                                <div className="col-12 mt-4">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" checked={step2.hasExpiry}
                                               onChange={() => setStep2({...step2, hasExpiry: !step2.hasExpiry})}/>
                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                            Set expiration date
                                        </label>
                                    </div>
                                </div>

                                {step2.hasExpiry &&
                                <div className="col-lg-4 col-md-6">
                                    <input type="date" className={"form-control"} required value={step2.expiry} min={formatDate(new Date())}
                                           onChange={(e) => setStep2({...step2, expiry: e.target.value})}/>
                                </div>
                                }

                                <button id="formSubmit2" className={"d-none"} type={"submit"}/>
                            </form>
                        </div>
                    </div>
                    }
                    {(step === 3) &&
                    <div className="row mt-4 mx-2">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h4>Much better.. There's no "i" in team!</h4>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <form onSubmit={handleSubmit3} className="row">
                                <div className="col-12">
                                    <h5>Who is going to manage this budget?</h5>
                                    <small className={"text-muted"}>Budget owners can add people to ths budget, send funds to members of this budget,
                                        and approve payments that come out of this budget. <strong>At least one is required.</strong></small>
                                </div>

                                <div className="col-12 mt-3">
                                    {step3.owners.length === 0 && <u className={"text-primary mb-1"} style={{cursor: "pointer"}} onClick={toggleOwner}>+ Add budget owners</u>}
                                    {step3.owners.length > 0 && <u className={"text-primary mb-1"} style={{cursor: "pointer"}} onClick={toggleOwner}>Edit budget owners</u>}
                                    {step3.owners.map((owner, index) => {
                                        return (
                                            <div className="row mb-2" key={"owner" + index}>
                                                <div className="col-lg-12">
                                                    <UserAvatar size="30" className={"text-white font-weight-bold float-left"} width={20}
                                                                name={owner.primaryContact_firstName + " " + owner.primaryContact_lastName}/>
                                                    <h6 className={"ml-2 mt-2 float-left"}>{owner.primaryContact_firstName} {owner.primaryContact_lastName}</h6>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                                <div className="col-12 mt-5">
                                    <h5>Who is going to be spending from this budget on a regular basis?</h5>
                                    <small className={"text-muted"}>Budget member can spend budget funds via funds requested or funds that a budget owner sends to them.</small>
                                </div>

                                <div className="col-12 mt-3">
                                    {step3.members.length === 0 && <u className={"text-primary mb-1"} style={{cursor: "pointer"}} onClick={toggleMember}>+ Add budget members</u>}
                                    {step3.members.length > 0 && <u className={"text-primary mb-1"} style={{cursor: "pointer"}} onClick={toggleMember}>Edit budget members</u>}
                                    {step3.members.map((member, index) => {
                                        return (
                                            <div className="row mb-2" key={"member" + index}>
                                                <div className="col-lg-12">
                                                    <UserAvatar size="30" className={"text-white font-weight-bold float-left"} width={20}
                                                                name={member.primaryContact_firstName + " " + member.primaryContact_lastName}/>
                                                    <h6 className={"ml-2 mt-2 float-left"}>{member.primaryContact_firstName} {member.primaryContact_lastName}</h6>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                                <button id="formSubmit3" className={"d-none"} type={"submit"}/>

                                <Modal isOpen={ownerModal} toggle={toggleOwner} modalClassName={'right'}>
                                    <ModalHeader toggle={toggleOwner}>Add budget owners</ModalHeader>
                                    <ModalBody>
                                        <div className="row">
                                            <div className="col">
                                                <input type="text" className={"form-control mb-4"} placeholder={"Search by name"}
                                                       onChange={(e) => searchPeople(e.target.value)}/>
                                            </div>
                                        </div>
                                        {
                                            filteredPeople.map((person, index) => {
                                                return (<div className="row mb-2" key={"person" + index}>
                                                    <div className="col-lg-12">
                                                        <UserAvatar size="30" className={"text-white font-weight-bold float-left"} width={20}
                                                                    name={person.primaryContact_firstName + " " + person.primaryContact_lastName}/>

                                                        <h6 className={"d-inline float-left ml-2 mt-2"}>{person.primaryContact_firstName} {person.primaryContact_lastName}</h6>
                                                        {peopleStatus[person.id] === 'OWNER' &&
                                                        <i className={"fa fa-check float-right mt-2"}
                                                           style={{cursor: "pointer"}}
                                                           onClick={() => unselectOwner(person)}/>
                                                        }
                                                        {peopleStatus[person.id] === null &&
                                                        <i className={"far fa-square float-right mt-2"}
                                                           style={{cursor: "pointer"}}
                                                           onClick={() => selectOwner(person)}/>
                                                        }
                                                    </div>
                                                </div>)
                                            })
                                        }
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={toggleOwner}>Close</Button>
                                    </ModalFooter>
                                </Modal>

                                <Modal isOpen={memberModal} toggle={toggleMember} modalClassName={'right'}>
                                    <ModalHeader toggle={toggleMember}>Add budget members</ModalHeader>
                                    <ModalBody>
                                        <div className="row">
                                            <div className="col">
                                                <input type="text" className={"form-control mb-4"} placeholder={"Search by name"}
                                                       onChange={(e) => searchPeople(e.target.value)}/>
                                            </div>
                                        </div>
                                        {
                                            filteredPeople.map((person, index) => {
                                                return (<div className="row mb-2" key={"person" + index}>
                                                    <div className="col-lg-12">
                                                        <UserAvatar size="30" className={"text-white font-weight-bold float-left"} width={20}
                                                                    name={person.primaryContact_firstName + " " + person.primaryContact_lastName}/>

                                                        <h6 className={"d-inline float-left ml-2 mt-2"}>{person.primaryContact_firstName} {person.primaryContact_lastName}</h6>
                                                        {peopleStatus[person.id] === 'MEMBER' &&
                                                        <i className={"fa fa-check float-right mt-2"}
                                                           style={{cursor: "pointer"}}
                                                           onClick={() => unselectMember(person)}/>
                                                        }
                                                        {peopleStatus[person.id] === null &&
                                                        <i className={"far fa-square float-right mt-2"}
                                                           style={{cursor: "pointer"}}
                                                           onClick={() => selectMember(person)}/>
                                                        }
                                                    </div>
                                                </div>)
                                            })
                                        }
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={toggleMember}>Close</Button>
                                    </ModalFooter>
                                </Modal>
                            </form>
                        </div>
                    </div>
                    }
                    {(step === 4) &&
                    <div className="row mt-4 mx-2">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h4>Almost done. Feel free to dial some things in with these advance settings.</h4>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <form onSubmit={handleSubmit4} className="row">
                                <div className="col-12">
                                    <h5>Spend controls.</h5>
                                    <small className={"text-muted"}>In a budget you can either have funds given to you to spend any available funds in the budget.
                                        This setting can be changed for owner and members.</small>
                                </div>

                                <div className="col-12 mt-3">
                                    <small className={"text-muted"}>This setting can be changed anytime.</small>
                                </div>

                                <div className="col-12 mt-5 text-muted">
                                    SHARED BUDGET FUNDS
                                </div>

                                <div className="col-12 mt-2">
                                    <div className="btn-group btn-group-sm border" role="group" aria-label="Small button group">
                                        <button type="button" onClick={() => {
                                            setStep4({control: 'OWNER'})
                                        }}
                                                className={`btn ${step4.control === "OWNER" ? 'btn-outline-secondary font-weight-bold' : 'btn-secondary'}`}>OWNERS
                                        </button>
                                        <button type="button" onClick={() => {
                                            setStep4({control: 'EVERYONE'})
                                        }}
                                                className={`btn ${step4.control === "EVERYONE" ? 'btn-outline-secondary font-weight-bold' : 'btn-secondary'}`}>EVERYONE
                                        </button>
                                        <button type="button" onClick={() => {
                                            setStep4({control: 'OFF'})
                                        }}
                                                className={`btn ${step4.control === "OFF" ? 'btn-outline-secondary font-weight-bold' : 'btn-secondary'}`}>OFF
                                        </button>
                                    </div>
                                    <br/>
                                    <small className={"text-muted"}>Add people to spend from any available funds in the budget.
                                        No need to send funds to people in the budget.</small>
                                </div>

                                <button id="formSubmit4" className={"d-none"} type={"submit"}/>
                            </form>
                        </div>
                    </div>
                    }
                    {(step === 5) &&
                    <div>
                        <div className="row mt-4 mx-2">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <h4>How are things looking?</h4>
                            </div>
                        </div>
                        <div className="row mt-4 mx-2">
                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <h6 className={"d-inline-block"}>Name and limit</h6> <Link onClick={() => (setStep(1))}>Edit</Link><br/>
                                <small className={"text-muted"}>BUDGET NAME</small>
                                <h2 className={"mb-2"}>{step1.name}</h2>
                                <small className={"text-muted"}>BUDGET LIMIT</small>
                                <h2 className={"mb-2"}>
                                    <NumberFormat value={Number(step1.budget).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'}/>
                                </h2>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <h6 className={"d-inline-block"}>Dates and schedule</h6> <Link onClick={() => (setStep(2))}>Edit</Link><br/>
                                <small className={"text-muted"}>BUDGET CYCLE</small><br/>
                                <strong className={"mt-1 d-block mb-0"}>{step2.cycle === 'ONETIME' ? 'One Time' : 'Recurring'}</strong>
                                <small className={"text-muted d-block mb-3"}>Set limits and work towards them. Limits never reset.</small>

                                {step2.hasExpiry && <div>
                                    <small className={"text-muted"}>EXPIRATION DATE</small><br/>
                                    <strong className={"d-block"}>
                                        <Moment date={step2.expiry} format={"MMMM DD, YYYY"}/>
                                    </strong>
                                </div>
                                }
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12">
                                <h6 className={"d-inline-block"}>Roster</h6> <Link onClick={() => (setStep(3))}>Edit</Link><br/>
                                <small className={"text-muted"}>OWNERS ({step3.owners.length})</small><br/>
                                {step3.owners.map((owner, index) => {
                                    return (
                                        <div className="row mb-2" key={"owner" + index}>
                                            <div className="col-lg-12">
                                                <UserAvatar size="30" className={"text-white font-weight-bold float-left"} width={20}
                                                            name={owner.primaryContact_firstName + " " + owner.primaryContact_lastName}/>
                                                <h6 className={"ml-2 mt-2 float-left"}>{owner.primaryContact_firstName} {owner.primaryContact_lastName}</h6>
                                            </div>
                                        </div>
                                    )
                                })}

                                <br/>
                                <small className={"text-muted"}>MEMBERS ({step3.members.length})</small><br/>
                                {step3.members.map((member, index) => {
                                    return (
                                        <div className="row mb-2" key={"members" + index}>
                                            <div className="col-12">
                                                <UserAvatar size="30" className={"text-white font-weight-bold float-left"} width={20}
                                                            name={member.primaryContact_firstName + " " + member.primaryContact_lastName}/>
                                                <h6 className={"ml-2 mt-2 float-left"}>{member.primaryContact_firstName} {member.primaryContact_lastName}</h6>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="row mt-5 mx-2">
                            <div className="col-12">
                                <h6 className={"d-inline-block"}>Spend controls</h6> <Link onClick={() => (setStep(4))}>Edit</Link><br/>
                                <small className={"text-muted"}>SHARED BUDGET FUNDS</small><br/>

                                <strong className={"mt-1 d-block mb-0"}>{step4.control === 'OWNER' ? 'Owners' : step2.cycle === 'EVERYONE' ? 'Everyone' : 'Off'}</strong>
                                <small className={"text-muted d-block"}>Allow people to spend from any available funds in the budget.
                                    No need to send funds to people in budget.
                                </small>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default AddBudget;
