import React from "react";
import MainRouter from "./modules/Router/MainRouter";
// Importing toastify module
import {toast} from 'react-toastify';

// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
import './assets/custom/custom.css';

// toast-configuration method,
// it is compulsory method.
toast.configure({position: "top-right", autoClose: 3000, newestOnTop: true, hideProgressBar: false});

const App = () => {
  return <MainRouter />;
};

export default App;
