import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../../Provider/AuthContext";
import {useHistory} from "react-router-dom";
import api from "../../../../config/api";
import {Breadcrumb, BreadcrumbItem, Table} from "reactstrap";
import {formatDate} from "../../../utils/helper";
import _ from "lodash";

const AllPeople = () => {
    const [applicationState]  = useContext(AuthContext);
    const history             = useHistory();
    const [state, setState]   = useState({users: []});

    const [filteredPeople, setFilteredPeople] = useState([]);


    const getAllPeople = async () => {
        let res = await api.get('/api/people');
        res.data.forEach(data => {
            data["name"]   = data.primaryContact_firstName + " " + data.primaryContact_lastName;
            data.createdAt = data ? data.createdAt ? formatDate(data.createdAt) : null : null;
        })
        return res.data;
    }

    const searchPeople = async (search) => {
        if(search.length === 0) {
            setFilteredPeople(state.users);
        } else {
            let filtered = _.filter(state.users, (person) => {
                return person.primaryContact_firstName.indexOf(search) !== -1 || person.primaryContact_lastName.indexOf(search) !== -1
            });
            setFilteredPeople(filtered);
        }
    }


    useEffect(() => {
        getAllPeople()
            .then((res) => {
                setState((prevState) => ({
                    ...prevState,
                    users: res
                }));
                setFilteredPeople(res);
            })
            .catch()
    }, []);

    const onButtonClick = (event) => {
        event.preventDefault();
        window.location.href = "/addperson";
    }

    return (
        <div className="">
            <div className="row">
                <div className="col-12">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <a href="/dashboard">{applicationState.companyName ? applicationState.companyName : 'Home'}</a>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            <a href="/people">People</a>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </div>
            </div>
            <div className="card">
                <div className="card-header bg-white">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <h1>People</h1>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <button type={"button"} className="btn btn-success float-right"disabled={applicationState.role !== "ADMIN"}  onClick={onButtonClick}>Add person</button>
                            <button type={"button"} className="btn btn-light mr-2 float-right" disabled={true}>Order Physical Card</button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-12 form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
											<span className="input-group-text bg-white border-right-0">
												<i className="fa fa-search"/>
											</span>
                                </div>
                                <input type="text" className="form-control border-left-0" placeholder="Search" onChange={(e) => searchPeople(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 form-group -search">
                            <div className="input-group">
                                <button type="button" className="btn btn-light" disabled={true}><i className="fa fa-plus"></i> &nbsp; Add Filter</button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Table hover responsive>
                                <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Role</th>
                                    <th scope="col">Membership Status</th>
                                    <th scope="col">Physical Card Status</th>
                                    <th scope="col">Date Added</th>
                                </tr>
                                </thead>
                                <tbody>
                                {filteredPeople.map((item, index) => (
                                    <tr key={index}>
                                        <td scope="row">{item.name}</td>
                                        <td>{(item.role === 'ADMIN') ? 'Admin' : 'Member'}</td>
                                        <td>{(item.isActive === true) ? 'Active' : 'Inactive'}</td>
                                        <td>Inactive</td>
                                        <td>{item.createdAt}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default AllPeople;
