import React, {useContext, useState} from "react";
import {Button, Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, NavItem, NavLink, UncontrolledDropdown} from "reactstrap";
import * as data from "./Data";

import logodarkicon from "../../../../assets/images/logo-icon.png";
import logolighticon from "../../../../assets/images/logo-light-icon.png";
import logodarktext from "../../../../assets/images/logo-text.png";
import logolighttext from "../../../../assets/images/logo-light-text.png";
import AuthContext from "../../../Provider/AuthContext";
import {useHistory} from "react-router-dom";
import UserAvatar from "react-user-avatar";

export default () => {
    const history                 = useHistory();
    const [inputState, callbacks] = useContext(AuthContext);
    const [isOpen, setIsOpen]     = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const showMobilemenu = () => {
        document.getElementById("main-wrapper").classList.toggle("show-sidebar");
    };

    const sidebarHandler = () => {
        let element      = document.getElementById("main-wrapper");
        let navbarHeader = document.getElementById("logobg");
        switch(inputState.activeSidebarType) {
            case null:
            case undefined:
            case "full":
            case "iconbar":
                element.classList.toggle("mini-sidebar");
                if(element.classList.contains("mini-sidebar")) {
                    element.setAttribute("data-sidebartype", "mini-sidebar");
                } else {
                    element.setAttribute("data-sidebartype", inputState.activeSidebarType);
                }
                navbarHeader.classList.remove("expand-logo");
                callbacks.handleChange("mini-sidebar", "activeSidebarType");
                break;
            case "overlay":
            case "mini-sidebar":
                element.classList.toggle("full");
                if(element.classList.contains("full")) {
                    element.setAttribute("data-sidebartype", "full");
                } else {
                    element.setAttribute("data-sidebartype", inputState.activeSidebarType);
                }
                callbacks.handleChange("full", "activeSidebarType");
                break;
            default:
        }
    };

    const handleLogout = (e) => {
        e.preventDefault();

        localStorage.clear();
        history.push("/login");
    }

    return (
        <header className="topbar navbarbg" data-navbarbg={inputState.activeNavbarBg}>
            <Navbar className={"top-navbar " + (inputState.activeNavbarBg === "skin6" ? "navbar-light" : "navbar-dark")} expand="md">
                <div className="navbar-header" id="logobg" data-logobg={inputState.activeLogoBg}>
				<span className="nav-toggler d-block d-md-none" onClick={showMobilemenu.bind(null)}>
                    <i className="ti-menu ti-close"/>
                </span>
                    <NavbarBrand>
                        <b className="logo-icon">
                            <img src={logodarkicon} alt="homepage" className="dark-logo"/>
                            <img src={logolighticon} alt="homepage" className="light-logo"/>
                        </b>
                        <span className="logo-text">
			            <img src={logodarktext} alt="homepage" className="dark-logo"/>
			            <img src={logolighttext} className="light-logo" alt="homepage"/>
                    </span>
                    </NavbarBrand>
                    <span className="topbartoggler d-block d-md-none" onClick={toggle.bind(null)}>
                    <i className="ti-more"/>
                </span>
                </div>
                <Collapse className="navbarbg" isOpen={isOpen} navbar data-navbarbg={inputState.activeNavbarBg}>
                    <Nav className="float-left" navbar>
                        <NavItem>
                            <NavLink href="#" className="d-none d-md-block" onClick={sidebarHandler.bind(null)}>
                                <i className="ti-menu"/>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <Nav className="ml-auto float-right" navbar>

                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                <i className="mdi mdi-bell font-24"/>
                            </DropdownToggle>
                            <DropdownMenu right className="mailbox">
                                <span className="with-arrow"><span className="bg-primary"/></span>
                                <div className="d-flex drop-title no-block align-items-center p-3 bg-primary text-white mb-2">
                                    <div className="">
                                        <h4 className="mb-0">4 New</h4>
                                        <p className="mb-0">Notifications</p>
                                    </div>
                                </div>
                                <div className="message-center notifications">
                                    {/*<!-- Message -->*/}
                                    {data.notifications.map((notification, index) => {
                                        return (
                                            <span className="message-item" key={index}>
                                        <span className={"btn btn-circle btn-" + notification.iconbg}><i className={notification.iconclass}/></span>
				                        <div className="mail-contnet">
				                            <h5 className="message-title">
				                            {notification.title}
				                            </h5>
				                            <span className="mail-desc">{notification.desc}</span>
				                            <span className="time">{notification.time}</span>
				                        </div>
                                    </span>
                                        );
                                    })}
                                </div>
                                <a className="nav-link text-center mb-1 text-dark" href=";">
                                    <strong>Check all notifications</strong>{" "}
                                    <i className="fa fa-angle-right"/>
                                </a>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* End Notifications Dropdown                                                     */}
                        {/*--------------------------------------------------------------------------------*/}
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Start Messages Dropdown                                                        */}
                        {/*--------------------------------------------------------------------------------*/}
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                <i className="font-24 mdi mdi-comment-processing"/>
                            </DropdownToggle>
                            <DropdownMenu right className="mailbox">
		                <span className="with-arrow">
		                  <span className="bg-danger"/>
		                </span>
                                <div className="d-flex drop-title no-block align-items-center p-3 bg-danger text-white mb-2">
                                    <div className="">
                                        <h4 className="mb-0">5 New</h4>
                                        <p className="mb-0">Messages</p>
                                    </div>
                                </div>
                                <div className="message-center message-body">
                                    {/*<!-- Message -->*/}
                                    {data.messages.map((message, index) => {
                                        return (
                                            <span href="" className="message-item" key={index}>
												<span className="user-img">
												  <UserAvatar size="40" className={"text-white font-weight-bold float-left"} width={40}
                                                              name={inputState.name || "? ?"}/>
												  <span className={"profile-status pull-right " + message.status}/>
											</span>
											<div className="mail-contnet">
											  <h5 className="message-title">{message.title}</h5>
											  <span className="mail-desc">{message.desc}</span>
											  <span className="time">{message.time}</span>
											</div>
										  </span>
                                        );
                                    })}
                                </div>
                                <span className="nav-link text-center link text-dark" href="">
								  	<b>See all e-Mails</b> <i className="fa fa-angle-right"/>
								</span>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* End Messages Dropdown                                                          */}
                        {/*--------------------------------------------------------------------------------*/}
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Start Profile Dropdown                                                         */}
                        {/*--------------------------------------------------------------------------------*/}
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret className="pro-pic pt-3">
                                <UserAvatar size="30" className={"text-dark font-weight-bold float-left"} width={30} color={"white"}
                                            name={inputState.name || "? ?"}/>
                            </DropdownToggle>
                            <DropdownMenu right className="user-dd">
                <span className="with-arrow">
                  <span className="bg-primary"/>
                </span>
                                <div className="d-flex no-block align-items-center p-3 bg-primary text-white mb-2">
                                    <div className="">
                                        <UserAvatar size="40" className={"text-white font-weight-bold float-left"} width={40}
                                                    name={inputState.name || "? ?"}/>
                                    </div>
                                    <div className="ml-2">
                                        <h4 className="mb-0">{inputState.name}</h4>
                                        <p className=" mb-0">{inputState.email}</p>
                                    </div>
                                </div>
                                <DropdownItem>
                                    <i className="ti-user mr-1 ml-1"/> My Account
                                </DropdownItem>
                                <DropdownItem>
                                    <i className="ti-wallet mr-1 ml-1"/> My Balance
                                </DropdownItem>
                                <DropdownItem>
                                    <i className="ti-email mr-1 ml-1"/> Inbox
                                </DropdownItem>
                                <DropdownItem divider/>
                                <DropdownItem>
                                    <i className="ti-settings mr-1 ml-1"/> Account Settings
                                </DropdownItem>
                                <DropdownItem divider/>
                                <DropdownItem onClick={handleLogout}>
                                    <i className="fa fa-power-off mr-1 ml-1"/> Logout
                                </DropdownItem>
                                <DropdownItem divider/>
                                <Button color="success" className="btn-rounded ml-3 mb-2 mt-2">
                                    View Profile
                                </Button>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* End Profile Dropdown                                                           */}
                        {/*--------------------------------------------------------------------------------*/}
                    </Nav>
                </Collapse>
            </Navbar>
        </header>
    );
};
