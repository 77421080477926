import React from "react";
import {Link} from "react-router-dom";
import img1 from "../../assets/images/logo-icon.png";
import img2 from "../../assets/images/background/login-register.jpg";

const sidebarBackground = {
	backgroundImage: "url(" + img2 + ")",
	backgroundRepeat: "no-repeat",
	backgroundPosition: "bottom center",
};

const PrivateRouter = (props) => {
	return (
	<div className="authentication">
		<div className="">
			{/*--------------------------------------------------------------------------------*/}
			{/*Login Cards*/}
			{/*--------------------------------------------------------------------------------*/}
			<div className="auth-wrapper d-flex no-block justify-content-center align-items-center"
			     style={sidebarBackground}>
				<div className="auth-box on-sidebar w-100">
					<div id="loginform">
						<div className="logo">
                <span className="db">
                  <img src={img1} alt="logo"/>
                </span>
							<h5 className="font-medium mb-3">Sign In to Admin</h5>
							<div className="alert alert-success">
								<Link to="/login">Login </Link>
								&nbsp;or&nbsp;
								<Link to="/signup"> SignUp</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	);
};

export default PrivateRouter;
