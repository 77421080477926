import React, {useEffect, useState} from 'react';
import _ from "lodash";
import {Modal, ModalBody} from "reactstrap";
import '../../../../assets/custom/bottom-modal.scss';
import api from "../../../../config/api";
import {formatDate} from "../../../utils/helper";
import {Multiselect} from 'multiselect-react-dropdown';
import NumberFormat from "react-number-format";
import UserAvatar from "react-user-avatar";
import {toast} from "react-toastify";

const SendFundModal = (props) => {
    const [reqObj, setReqObj]     = useState({
        budget: null,
        users: [],
        sendAmount: null,
        note: null
    });
    const [budgetId, setBudgetId] = useState(props.budgetId);
    const [state, setState]       = useState({budgets: []});
    const onClose                 = () => {
        props.onClose(!props.isOpen);
        reload();
    }
    const [users, setUsers]       = useState({users: []});

    const onCancel     = () => {
        props.onClose(!props.isOpen);
        reload();
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        // send fund api call here on success or failure close the modal and show toastr
        if((reqObj.budget.availableAmount - (reqObj.budget.assignedAmount + reqObj.budget.spentAmount)) >= (reqObj.users.length * reqObj.sendAmount)) {
            let response = await api.post('/api/budgets/sendfund', reqObj);
            toast.success(response.data.message);
            props.onClose(!props.isOpen);
        } else {
            toast.warning("Can not send funds more than the available amount in the budget.");
        }
        // reload();
    }

    const getBudgets = async () => {
        let res = await api.get('/api/budgets');
        res.data.forEach(data => {
            data.expirationDate = data ? data.expirationDate ? formatDate(data.expirationDate) : null : null;
        })
        return res.data;
    }

    useEffect(() => {
        getBudgets()
            .then((res) => {
                setState((prevState) => ({
                    ...prevState,
                    budgets: res
                }));
                return res;
            })
            .then((budgets) => {
                if(props.budgetId && props.budgetId !== '') {
                    let budget = _.find(budgets, {id: Number(props.budgetId)});
                    if(budget && budget.members && budget.members.length > 0) {
                        let mappedArray = _.map(budget.members, member => {
                            return {id: member.userId, name: member.primaryContact_firstName + " " + member.primaryContact_lastName}
                        })
                        setUsers({users: mappedArray});
                        setReqObj((prevState) => ({
                            ...prevState,
                            budget: budget
                        }))
                    } else {
                        setUsers({users: []});
                        setReqObj((prevState) => ({
                            ...prevState,
                            budget: null
                        }))
                    }
                }
            })
            .catch()
    }, []);


    const handleChange = (e) => {
        e.preventDefault();
        e.persist();
        setBudgetId(Number(e.target.value));
        let budget = _.find(state.budgets, {id: Number(e.target.value)});
        if(budget && budget.members && budget.members.length > 0) {
            let mappedArray = _.map(budget.members, member => {
                console.log(member);
                return {id: member.userId, name: member.primaryContact_firstName + " " + member.primaryContact_lastName}
            })
            setUsers({users: mappedArray});
            setReqObj((prevState) => ({
                ...prevState,
                budget: budget
            }))
        } else {
            setUsers({users: []});
            setReqObj((prevState) => ({
                ...prevState,
                budget: null
            }))
        }
    };

    const onSelect = (selectedList, selectedItem) => {
        setReqObj((prevState) => ({
            ...prevState,
            users: selectedList
        }))
    }

    const onRemove = (selectedList, removedItem) => {
        setReqObj((prevState) => ({
            ...prevState,
            users: selectedList
        }))
    }

    const handleNoteChange = (e) => {
        e.preventDefault();
        e.persist();
        console.log(e.target.value);
        setReqObj((prevState) => ({
            ...prevState,
            note: e.target.value
        }))
    }

    const reload = () => window.location.reload();

    return (
        <Modal isOpen={props.isOpen} toggle={onClose} modalClassName={'modal-bottom'} backdrop={'static'} keyboard={false} onExit={onClose}>
            <ModalBody>
                <div className="container-fluid">
                    <div className="row m-lg-5">
                        <div className="col-lg-8 col-md-6 col-sm-12">
                            <h3 className={"mt-1"}>Send funds</h3>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <button type={"button"} className="btn btn-success mr-2 float-right"
                                    disabled={reqObj.users.length === 0 || reqObj.sendAmount <= 0.00 || reqObj.sendAmount === '' || reqObj.note === null}
                                    onClick={() => document.getElementById("formSubmit").click()}>Send
                                ${(reqObj.users.length > 0 ? (reqObj.sendAmount ? (reqObj.sendAmount * reqObj.users.length) : '0.00') : (reqObj.sendAmount ? reqObj.sendAmount : '0.00'))}</button>
                            <button type={"button"} className="btn btn-light mr-2 float-right" onClick={onCancel}>Cancel</button>
                        </div>
                    </div>
                    <div className="row m-lg-5">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="card border-right">
                                <div className="card-header bg-white">
                                    <h4 className={"card-title"}>Options</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor={"budget"}>Budget</label>
                                                <select
                                                    id={"budget"}
                                                    name={"budget"}
                                                    className={"form-control"}
                                                    value={budgetId}
                                                    key={props.budgetId}
                                                    onChange={handleChange}
                                                    disabled={props.budgetId}
                                                    required>
                                                    <option value={""} key={""}>Select Budget</option>
                                                    {state.budgets.map(({id, name}, index) => <option key={index} value={id}>{name}</option>)}
                                                </select>
                                            </div>
                                            {reqObj.budget &&
                                            <div className="col-12 mt-1">
                                                <h6 className="text-secondary">Available
                                                    Balance: ${reqObj.budget ? (reqObj.budget.availableAmount - (reqObj.budget.spentAmount + reqObj.budget.assignedAmount)) : null}</h6>
                                            </div>
                                            }

                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <label htmlFor="users">People</label>
                                                <Multiselect
                                                    className={"form-control"}
                                                    showArrow={true}
                                                    placeholder="Select a person/people"
                                                    options={users.users}
                                                    displayValue="name"
                                                    // groupBy={"role"}
                                                    showCheckbox={true}
                                                    selectedValues={reqObj.users}
                                                    onSelect={onSelect}
                                                    onRemove={onRemove}
                                                    closeIcon="close"/>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <label htmlFor="sendAmount">Amount (Each)</label>
                                                <NumberFormat thousandSeparator={true}
                                                              decimalSeparator={'.'}
                                                              prefix={"$"}
                                                              className={"form-control form-control-lg"}
                                                              defaultValue={reqObj.sendAmount}
                                                              onValueChange={(values) => {
                                                                  const {value, floatValue} = values;
                                                                  setReqObj({...reqObj, sendAmount: floatValue})
                                                              }}
                                                              allowEmptyFormatting={true}
                                                              allowNegative={false}
                                                              placeholder={''}
                                                              id={"sendAmount"}
                                                              required/>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <label htmlFor="note">Note</label>
                                                <textarea className={"form-control"} style={{resize: "none"}}
                                                          name="note" id="note"
                                                          cols="30" rows="10"
                                                          defaultValue={reqObj.note} onChange={handleNoteChange}/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <button id="formSubmit" className={"d-none"} type={"submit"}/>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="card border-left">
                                <div className="card-header bg-white">
                                    <h4 className={"card-title"}>Change Summary</h4>
                                </div>
                                <div className="card-body pt-0">
                                    {(reqObj.budget === null || reqObj.note === null) && <div className="change-summary"></div>}
                                    {(reqObj.budget !== null && reqObj.note !== null) &&
                                    <div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <span>Available In Budget</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h3>${reqObj.budget.availableAmount - (reqObj.budget.assignedAmount + reqObj.budget.spentAmount)}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 float-right">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <span>Total Send Amount</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h3>${(reqObj.users.length > 0 && reqObj.sendAmount !== null) ? (reqObj.users.length * reqObj.sendAmount) : 0.00}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'row mt-5'}>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <span>Select the people to receive funds</span>
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-12">
                                                        {reqObj.users.length > 0 && reqObj.users.map(user => {
                                                            return (
                                                                <div className="row" key={user.id}>
                                                                    <div className="col-12">
                                                                        <UserAvatar size="30" className={"text-white font-weight-bold float-left"} width={20}
                                                                                    name={user.name}/>

                                                                        <h6 className={"d-inline float-left ml-2 mt-2"}>{user.name}</h6>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default SendFundModal;
