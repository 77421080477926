import React, {Component} from "react";
import NumberFormat from "react-number-format";

export default class PrimaryContact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            primaryContact_firstName: props.data.primaryContact_firstName,
            primaryContact_lastName: props.data.primaryContact_lastName,
            primaryContact_residentialAddress: props.data.primaryContact_residentialAddress,
            primaryContact_workEmail: props.data.primaryContact_workEmail,
            primaryContact_phoneNumber: props.data.primaryContact_phoneNumber,
            primaryContact_dateOfBirth: props.data.primaryContact_dateOfBirth,
            primaryContact_ss: props.data.primaryContact_ss,
            primaryContact_title: props.data.primaryContact_title,
            stepAt: props.data.stepAt,
        };

        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms

        this.validationCheck = this.validationCheck.bind(this);
        this.isValidated     = this.isValidated.bind(this);
    }

    isValidated() {
        const userInput        = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator
        let isDataValid        = false;

        // if full validation passes then save to store and pass as valid
        if(
            Object.keys(validateNewInput).every((k) => {
                return validateNewInput[k] === true;
            })
        ) {
            if(
                this.props.data.primaryContact_firstName !== userInput.primaryContact_firstName ||
                this.props.data.primaryContact_lastName !== userInput.primaryContact_lastName ||
                this.props.data.primaryContact_residentialAddress !== userInput.primaryContact_residentialAddress ||
                this.props.data.primaryContact_workEmail !== userInput.primaryContact_workEmail ||
                this.props.data.primaryContact_phoneNumber !== userInput.primaryContact_phoneNumber ||
                this.props.data.primaryContact_dateOfBirth !== userInput.primaryContact_dateOfBirth ||
                this.props.data.primaryContact_ss !== userInput.primaryContact_ss || this.props.data.primaryContact_title !== userInput.primaryContact_title
            ) {// only update store of something changed
                this.props.onNext({
                    ...userInput, // use this to notify step4 that some changes took place and prompt the user to save again
                    stepAt: this.props.data.stepAt,
                }); // Update store here (this is just an example, in reality you will do it via redux or flux)
            }

            isDataValid = true;
        } else {
            // if anything fails then update the UI validation state but NOT the UI Data State
            this.setState(
                Object.assign(
                    userInput,
                    validateNewInput,
                    this._validationErrors(validateNewInput)
                )
            );
        }

        return isDataValid;
    }

    validationCheck() {
        if(!this._validateOnDemand) return;

        const userInput        = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator

        this.setState(
            Object.assign(
                userInput,
                validateNewInput,
                this._validationErrors(validateNewInput)
            )
        );
    }

    _validateData(data) {
        return {
            primaryContact_firstNameVal: data.primaryContact_firstName !== "",
            primaryContact_lastNameVal: data.primaryContact_lastName !== "",
            primaryContact_residentialAddressVal: data.primaryContact_residentialAddress !== "",
            primaryContact_workEmailVal: data.primaryContact_workEmail !== "",
            primaryContact_phoneNumberVal: data.primaryContact_phoneNumber !== "",
            primaryContact_dateOfBirthVal: data.primaryContact_dateOfBirth !== "",
            primaryContact_ssVal: data.primaryContact_ss !== "" && data.primaryContact_ss.length === 9,
            primaryContact_titleVal: data.primaryContact_title !== ""
        };
    }

    _validationErrors(val) {
        const errMsgs = {
            primaryContact_firstNameValMsg: val.primaryContact_firstNameVal
                ? ""
                : "First Name is required",
            primaryContact_lastNameValMsg: val.primaryContact_lastNameVal
                ? null
                : "Last Name is required",
            primaryContact_residentialAddressValMsg: val.primaryContact_residentialAddressVal
                ? null
                : "Residential AddreprimaryContact_ss is required",
            primaryContact_workEmailValMsg: val.primaryContact_workEmailVal
                ? null
                : "Work Email is required",
            primaryContact_phoneNumberValMsg: val.primaryContact_phoneNumberVal
                ? null
                : "Phone Number is required",
            primaryContact_dateOfBirthValMsg: val.primaryContact_dateOfBirthVal
                ? null
                : "Date Of Birth is required",
            primaryContact_ssValMsg: val.primaryContact_ssVal
                ? null
                : "SS is required and should have 9 digits",
            primaryContact_titleValMsg: val.primaryContact_titleVal
                ? null
                : "Title is required",
        };
        return errMsgs;
    }

    _grabUserInput() {
        return {
            primaryContact_firstName: this.primaryContact_firstName.value,
            primaryContact_lastName: this.primaryContact_lastName.value,
            primaryContact_residentialAddress: this.primaryContact_residentialAddress.value,
            primaryContact_workEmail: this.primaryContact_workEmail.value,
            primaryContact_phoneNumber: this.primaryContact_phoneNumber.value,
            primaryContact_dateOfBirth: this.primaryContact_dateOfBirth.value,
            primaryContact_ss: this.primaryContact_ss.state.numAsString,
            primaryContact_title: this.primaryContact_title.value
        };
    }

    render() {
        // explicit class assigning based on validation
        let notValidClasses = {};

        if(
            typeof this.state.primaryContact_firstNameVal === "undefined" ||
            this.state.primaryContact_firstNameVal
        ) {
            notValidClasses.primaryContact_firstNameCls = "form-control";
        } else {
            notValidClasses.primaryContact_firstNameCls       = "is-invalid form-control";
            notValidClasses.primaryContact_firstNameValGrpCls = "text-danger";
        }

        if(
            typeof this.state.primaryContact_lastNameVal === "undefined" ||
            this.state.primaryContact_lastNameVal
        ) {
            notValidClasses.primaryContact_lastNameCls = "form-control";
        } else {
            notValidClasses.primaryContact_lastNameCls       = "is-invalid form-control";
            notValidClasses.primaryContact_lastNameValGrpCls = "text-danger";
        }

        if(
            typeof this.state.primaryContact_residentialAddressVal === "undefined" ||
            this.state.primaryContact_residentialAddressVal
        ) {
            notValidClasses.primaryContact_residentialAddressCls = "form-control";
        } else {
            notValidClasses.primaryContact_residentialAddressCls       = "is-invalid form-control";
            notValidClasses.primaryContact_residentialAddressValGrpCls = "text-danger";
        }

        if(
            typeof this.state.primaryContact_workEmailVal === "undefined" ||
            this.state.primaryContact_workEmailVal
        ) {
            notValidClasses.primaryContact_workEmailCls = "form-control";
        } else {
            notValidClasses.primaryContact_workEmailCls       = "is-invalid form-control";
            notValidClasses.primaryContact_workEmailValGrpCls = "text-danger";
        }

        if(
            typeof this.state.primaryContact_phoneNumberVal === "undefined" ||
            this.state.primaryContact_phoneNumberVal
        ) {
            notValidClasses.primaryContact_phoneNumberCls = "form-control";
        } else {
            notValidClasses.primaryContact_phoneNumberCls       = "is-invalid form-control";
            notValidClasses.primaryContact_phoneNumberValGrpCls = "text-danger";
        }

        if(
            typeof this.state.primaryContact_dateOfBirthVal === "undefined" ||
            this.state.primaryContact_dateOfBirthVal
        ) {
            notValidClasses.primaryContact_dateOfBirthCls = "form-control";
        } else {
            notValidClasses.primaryContact_dateOfBirthCls       = "is-invalid form-control";
            notValidClasses.primaryContact_dateOfBirthValGrpCls = "text-danger";
        }

        if(
            typeof this.state.primaryContact_ssVal === "undefined" ||
            this.state.primaryContact_ssVal
        ) {
            notValidClasses.primaryContact_ssCls = "form-control";
        } else {
            notValidClasses.primaryContact_ssCls       = "is-invalid form-control";
            notValidClasses.primaryContact_ssValGrpCls = "text-danger";
        }

        if(
            typeof this.state.primaryContact_titleVal === "undefined" ||
            this.state.primaryContact_titleVal
        ) {
            notValidClasses.primaryContact_titleCls = "form-control";
        } else {
            notValidClasses.primaryContact_titleCls       = "is-invalid form-control";
            notValidClasses.primaryContact_titleValGrpCls = "text-danger";
        }

        return (
            <div className="step step1 mt-5 ">
                <div className="row justify-content-md-center">`
                    <div className="col col-lg-6">
                        <div className="">
                            <h4>Setup Your Primary Contract</h4>
                            {/* <h6 className="card-subtitle">
                            To comply with regulation we collect this information to confirm
                            that you are a business that is legally incorporated.
                          </h6> */}
                            <form id="Form" className="form-horizontal mt-5">
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">First Name</label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.primaryContact_firstName = f;
                                                }}
                                                autoComplete="off"
                                                type="text"
                                                className={notValidClasses.primaryContact_firstNameCls}
                                                required
                                                defaultValue={this.state.primaryContact_firstName}
                                                onBlur={this.validationCheck}
                                            />
                                            <div className={notValidClasses.primaryContact_firstNameValGrpCls}>
                                                {this.state.primaryContact_firstNameValMsg}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">Last Name</label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.primaryContact_lastName = f;
                                                }}
                                                autoComplete="off"
                                                type="text"
                                                className={notValidClasses.primaryContact_lastNameCls}
                                                required
                                                defaultValue={this.state.primaryContact_lastName}
                                                onBlur={this.validationCheck}
                                            />
                                            <div className={notValidClasses.primaryContact_lastNameValGrpCls}>
                                                {this.state.primaryContact_lastNameValMsg}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">
                                            Residential Address
                                        </label>
                                        <div>
                                        <textarea
                                            ref={(f) => {
                                                this.primaryContact_residentialAddress = f;
                                            }}
                                            autoComplete="off"
                                            rows="0"
                                            maxLength="255"
                                            className={notValidClasses.primaryContact_residentialAddressCls}
                                            required
                                            defaultValue={this.state.primaryContact_residentialAddress}
                                            onBlur={this.validationCheck}
                                        />
                                            <div
                                                className={notValidClasses.primaryContact_residentialAddressValGrpCls}
                                            >
                                                {this.state.primaryContact_residentialAddressValMsg}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">
                                            Work Email
                                        </label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.primaryContact_workEmail = f;
                                                }}
                                                autoComplete="off"
                                                type="email"
                                                className={notValidClasses.primaryContact_workEmailCls}
                                                required
                                                defaultValue={this.state.primaryContact_workEmail}
                                                onBlur={this.validationCheck}
                                            />
                                            <div
                                                className={
                                                    notValidClasses.primaryContact_workEmailValGrpCls
                                                }
                                            >
                                                {this.state.primaryContact_workEmailValMsg}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">
                                            Phone Number
                                        </label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.primaryContact_phoneNumber = f;
                                                }}
                                                autoComplete="off"
                                                type="text"
                                                className={notValidClasses.primaryContact_phoneNumberCls}
                                                required
                                                defaultValue={this.state.primaryContact_phoneNumber}
                                                onBlur={this.validationCheck}
                                            />
                                            <div
                                                className={notValidClasses.primaryContact_phoneNumberValGrpCls}
                                            >
                                                {this.state.primaryContact_phoneNumberValMsg}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">
                                            Date Of Birth
                                        </label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.primaryContact_dateOfBirth = f;
                                                }}
                                                autoComplete="off"
                                                type="date"
                                                className={notValidClasses.primaryContact_dateOfBirthCls}
                                                required
                                                defaultValue={this.state.primaryContact_dateOfBirth}
                                                onBlur={this.validationCheck}
                                            />
                                            <div
                                                className={
                                                    notValidClasses.primaryContact_dateOfBirthValGrpCls
                                                }
                                            >
                                                {this.state.primaryContact_dateOfBirthValMsg}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">SS #</label>
                                        <div>
                                            <NumberFormat
                                                ref={(f) => {
                                                    this.primaryContact_ss = f;
                                                }}
                                                format="#########"
                                                mask="_"
                                                className={notValidClasses.primaryContact_ssCls}
                                                defaultValue={this.state.primaryContact_ss}
                                                onBlur={(values) => {
                                                    const {formattedValue, value} = values;
                                                    this.validationCheck();
                                                }}
                                                allowEmptyFormatting
                                            />
                                            <div className={notValidClasses.primaryContact_ssValGrpCls}>
                                                {this.state.primaryContact_ssValMsg}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">Title</label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.primaryContact_title = f;
                                                }}
                                                autoComplete="off"
                                                type="text"
                                                className={notValidClasses.primaryContact_titleCls}
                                                required
                                                defaultValue={this.state.primaryContact_title}
                                                onBlur={this.validationCheck}
                                            />
                                            <div className={notValidClasses.primaryContact_titleValGrpCls}>
                                                {this.state.primaryContact_titleValMsg}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
