import React, {useState} from "react";
import img1 from "../../../../assets/images/logo-icon.png";
import img2 from "../../../../assets/images/background/login-register.jpg";
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import api from "../../../../config/api";
import jwt_decode from "jwt-decode";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";

const sidebarBackground = {
    backgroundImage: "url(" + img2 + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom center",
};

const Login = () => {
    const [state, setState] = useState({
        email: "",
        password: "",
        isSubmitted: false,
    });

    const [status, setStatus] = useState("IDLE");
    const history             = useHistory();

    const setEmail = async (e) => {
        let str = e.target.value;
        await setState({...state, email: str});
        localStorage.setItem("email", str);
    }

    const setPassword = async (e) => {
        let str = e.target.value;
        await setState({...state, password: str});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.persist();

        try {
            setStatus("ACTIVE");
            let response = await api.post("/api/auth/login", {
                email: state.email,
                password: state.password
            })
            setStatus("IDLE");
            await localStorage.setItem("token", response.data.token);
            const user = jwt_decode(response.data.token);
            await localStorage.setItem("isFlowCompleted", user.isFlowCompleted);
            await localStorage.setItem("userRole", user.role);
            if(user.role === 'ADMIN') {
                if(user.isFlowCompleted) {
                    // Dashboard for admin user
                    history.push("/dashboard"); // for now added
                } else {
                    history.push("/applicationflow");
                }
            } else if(user.role === 'MEMBER') {
                // dashboard for the user role
                history.push("/dashboard"); // for now added
            } else {
                setStatus("IDLE");
                await toast.error("Something Went Wrong.");
                history.push("/login");
                localStorage.clear();
            }
            toast.success(response.data.message);
        } catch(error) {
            setStatus("IDLE");
            if(error.response.status === 500) {
                toast.error(error.response.data.message);
            } else if(error.response.status === 404) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        }
    }

    return (
        <div className="authentication">
            <div className="">
                {/*--------------------------------------------------------------------------------*/}
                {/*Login Cards*/}
                {/*--------------------------------------------------------------------------------*/}
                <div className="auth-wrapper d-flex no-block justify-content-center align-items-center"
                     style={sidebarBackground}>
                    <div className="auth-box on-sidebar w-100">
                        <div id="loginform">
                            <div className="logo mt-5">
                                <span className="db"><img src={img1} alt="logo"/></span>
                                <h5 className="font-medium mb-3">Quarkie Log In</h5>
                            </div>

                            <div className="">
                                <Row>
                                    <Col xs="12">
                                        <Form className="mt-3" id="loginform" onSubmit={handleSubmit}>
                                            <FormGroup className="mb-3">
                                                <Label for="email">Email</Label>
                                                <Input
                                                    className="form-control"
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder="Email"
                                                    bsSize="lg"
                                                    onChange={setEmail}
                                                    value={state.email}
                                                    autoComplete="off"
                                                    required
                                                />
                                            </FormGroup>
                                            <FormGroup className="mb-3">
                                                <Label for="password">Password</Label>
                                                <Input
                                                    className="form-control"
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    placeholder="Password"
                                                    bsSize="lg"
                                                    onChange={setPassword}
                                                    value={state.password}
                                                    autoComplete="off"
                                                    required
                                                />
                                            </FormGroup>
                                            <Row className="mb-3 mt-3">
                                                <Col xs="12">
                                                    <Button
                                                        color="primary"
                                                        size="lg"
                                                        disabled={status === 'ACTIVE'}
                                                        type="submit"
                                                        block
                                                    >
                                                        Login
                                                        {status === 'ACTIVE' && <i className="ml-3 fa fa-spinner fa-spin"/>}
                                                    </Button>
                                                </Col>
                                                {
                                                    status === 'ACTIVE' &&
                                                    <Col xs="12">
                                            <span className="text-info">
                                                Logging in, please wait...
                                            </span>
                                                    </Col>
                                                }
                                            </Row>
                                            <Row>
                                                <Col xs="12"
                                                     className="text-right mt-3">
                                                    <h6 className="text-secondary">Don't have account? <a href="/signup">Sign Up</a></h6>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
