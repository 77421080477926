import React, {useState} from "react";
import OtpVerify from "./OtpVerify";
import VerifyEmail from "./VerifyEmail";
import img1 from "../../../../assets/images/logo-icon.png";
import img2 from "../../../../assets/images/background/login-register.jpg";
import {Col, Row} from "reactstrap";
import Password from "./Password";

const sidebarBackground = {
    backgroundImage: "url(" + img2 + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom center",
};

const SignUp = () => {
    let currentStage = localStorage.getItem("stage");

    const [inputState, setInputState] = useState({
        email: localStorage.getItem("email") || "",
        otp: "",
        password: "",
        confirmPassword: "",
        stage: ["VerifyEmail", "OtpVerify", "Password"].includes(currentStage) ? currentStage : "VerifyEmail",
        isSubmitted: false,
    });

    const handleCancel = (e) => {
        e.preventDefault();

        localStorage.clear();
        window.location.reload();
    };

    const onSubmit = (value) => {
        if(inputState.stage === "VerifyEmail") {
            setInputState((prevState) => ({
                ...prevState,
                email: value.email,
                stage: value.stage,
            }));
        } else if(inputState.stage === "OtpVerify") {
            setInputState((prevState) => ({
                ...prevState,
                otp: value.otp,
                stage: value.stage,
            }));
        } else if(inputState.stage === "Password") {
            setInputState((prevState) => ({
                ...prevState,
                password: value.password,
                confirmPassword: value.confirmPassword,
                stage: value.stage,
            }));
        }
    };

    let renderElement;
    renderElement = <div className="auth-wrapper d-flex no-block justify-content-center align-items-center" style={sidebarBackground}>
        <div className="auth-box on-sidebar w-100">
            <div id="loginform">
                <div className="logo mt-5">
                    <span className="db"><img src={img1} alt="logo"/></span>
                    <h5 className="font-medium mb-3">Quarkie Sign Up</h5>
                </div>
                {inputState.stage === "VerifyEmail" && (<VerifyEmail data={inputState} onSubmit={onSubmit}/>)}
                {inputState.stage === "OtpVerify" && (<OtpVerify data={inputState} onSubmit={onSubmit}/>)}
                {inputState.stage === "Password" && (<Password data={inputState} onSubmit={onSubmit}/>)}
                <Row>
                    <Col xs={12} className="text-right mt-3">
                        <a href="/" className="text-secondary" onClick={handleCancel}>Cancel Sign Up</a>
                    </Col>
                </Row>

                <Row>
                    <Col xs="12"
                         className="text-right">
                        <h6 className="text-secondary">Already have account? <a href="/login">Log in</a></h6>
                    </Col>
                </Row>
            </div>
        </div>
    </div>;

    return <div className="authentication">{renderElement}</div>;
};

export default SignUp;

