import React, {Component} from "react";

export default class CompanyInformation extends Component {
    constructor(props) {
        super(props);

        this.state             = {
            companyInfo_companyLegalName: props.data.companyInfo_companyLegalName,
            companyInfo_companyWebsite: props.data.companyInfo_companyWebsite,
            companyInfo_companyDescription: props.data.companyInfo_companyDescription,
            companyInfo_industry: props.data.companyInfo_industry,
            companyInfo_noOfFullTimeEmployee: props.data.companyInfo_noOfFullTimeEmployee,
            companyInfo_noOfPartTimeEmployee: props.data.companyInfo_noOfPartTimeEmployee,
            companyInfo_doesAnyoneDoInternationTravel: props.data.companyInfo_doesAnyoneDoInternationTravel,
            companyInfo_physicalAddress: props.data.companyInfo_physicalAddress,
            companyInfo_phone: props.data.companyInfo_phone,
            companyInfo_companyType: props.data.companyInfo_companyType,
            companyInfo_dateEstablished: props.data.companyInfo_dateEstablished,
            stepAt: props.data.stepAt
        };
        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms

        this.validationCheck = this.validationCheck.bind(this);
        this.isValidated     = this.isValidated.bind(this);
    }

    isValidated() {
        const userInput        = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator
        let isDataValid        = false;

        // if full validation passes then save to store and pass as valid
        if(
            Object.keys(validateNewInput).every((k) => {
                return validateNewInput[k] === true;
            })
        ) {
            if(
                this.props.data.companyInfo_companyLegalName !== userInput.companyInfo_companyLegalName ||
                this.props.data.companyInfo_companyWebsite !== userInput.companyInfo_companyWebsite ||
                this.props.data.companyInfo_companyDescription !== userInput.companyInfo_companyDescription ||
                this.props.data.companyInfo_industry !== userInput.companyInfo_industry ||
                this.props.data.companyInfo_noOfFullTimeEmployee !==
                userInput.companyInfo_noOfFullTimeEmployee ||
                this.props.data.companyInfo_noOfPartTimeEmployee !==
                userInput.companyInfo_noOfPartTimeEmployee ||
                this.props.data.companyInfo_doesAnyoneDoInternationTravel !==
                userInput.companyInfo_doesAnyoneDoInternationTravel ||
                this.props.data.companyInfo_physicalAddress !== userInput.companyInfo_physicalAddress ||
                this.props.data.companyInfo_phone !== userInput.companyInfo_phone ||
                this.props.data.companyInfo_companyType !== userInput.companyInfo_companyType ||
                this.props.data.companyInfo_dateEstablished !== userInput.companyInfo_dateEstablished
            ) {
                // only update store of something changed
                this.props.onNext({
                    ...userInput, // use this to notify step4 that some changes took place and prompt the user to save again
                    stepAt: this.props.data.stepAt,
                }); // Update store here (this is just an example, in reality you will do it via redux or flux)
            }

            isDataValid = true;
        } else {
            // if anything fails then update the UI validation state but NOT the UI Data State
            this.setState(
                Object.assign(
                    userInput,
                    validateNewInput,
                    this._validationErrors(validateNewInput)
                )
            );
        }

        return isDataValid;
    }

    validationCheck() {
        if(!this._validateOnDemand) return;

        const userInput        = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator

        this.setState(
            Object.assign(
                userInput,
                validateNewInput,
                this._validationErrors(validateNewInput)
            )
        );
    }

    _validateData(data) {
        return {
            companyInfo_companyLegalNameVal: data.companyInfo_companyLegalName !== "",
            companyInfo_companyWebsiteVal: data.companyInfo_companyWebsite !== "",
            companyInfo_companyDescriptionVal: data.companyInfo_companyDescription !== "",
            companyInfo_industryVal: data.companyInfo_industry !== "",
            companyInfo_noOfFullTimeEmployeeVal: data.companyInfo_noOfFullTimeEmployee !== "",
            companyInfo_noOfPartTimeEmployeeVal: data.companyInfo_noOfPartTimeEmployee !== "",
            companyInfo_doesAnyoneDoInternationTravelVal:
                data.companyInfo_doesAnyoneDoInternationTravel !== "",
            companyInfo_physicalAddressVal: data.companyInfo_physicalAddress !== "",
            companyInfo_phoneVal: data.companyInfo_phone !== "",
            companyInfo_companyTypeVal: data.companyInfo_companyType !== "",
            companyInfo_dateEstablishedVal: data.companyInfo_dateEstablished !== "",
        };
    }

    _validationErrors(val) {
        const errMsgs = {
            companyInfo_companyLegalNameValMsg: val.companyInfo_companyLegalNameVal
                ? ""
                : "Company's Legal Name is required",
            companyInfo_companyWebsiteValMsg: val.companyInfo_companyWebsiteVal
                ? ""
                : "Company Website is required",
            companyInfo_companyDescriptionValMsg: val.companyInfo_companyDescriptionVal
                ? ""
                : "Company Description is required",
            companyInfo_industryValMsg: val.companyInfo_industryVal ? "" : "Industry is required",
            companyInfo_noOfFullTimeEmployeeValMsg: val.companyInfo_noOfFullTimeEmployeeVal
                ? ""
                : "Number of Full Time Employee is required",
            companyInfo_noOfPartTimeEmployeeValMsg: val.companyInfo_noOfPartTimeEmployeeVal
                ? ""
                : "Number Of Part Time Employee is required",
            companyInfo_doesAnyoneDoInternationTravelValMsg: val.companyInfo_doesAnyoneDoInternationTravelVal
                ? ""
                : "Does Anyone Do Internation Travel is required",
            companyInfo_physicalAddressValMsg: val.companyInfo_physicalAddressVal
                ? ""
                : "Physical Address is required",
            companyInfo_phoneValMsg: val.companyInfo_phoneVal ? "" : "Phone is required",
            companyInfo_companyTypeValMsg: val.companyInfo_companyTypeVal ? "" : "Company Type is required",
            companyInfo_dateEstablishedValMsg: val.companyInfo_dateEstablishedVal
                ? ""
                : "Date Established is required",
        };
        return errMsgs;
    }

    _grabUserInput() {
        return {
            companyInfo_companyLegalName: this.companyInfo_companyLegalName.value,
            companyInfo_companyWebsite: this.companyInfo_companyWebsite.value,
            companyInfo_companyDescription: this.companyInfo_companyDescription.value,
            companyInfo_industry: this.companyInfo_industry.value,
            companyInfo_noOfFullTimeEmployee: this.companyInfo_noOfFullTimeEmployee.value,
            companyInfo_noOfPartTimeEmployee: this.companyInfo_noOfPartTimeEmployee.value,
            companyInfo_doesAnyoneDoInternationTravel: this.companyInfo_doesAnyoneDoInternationTravel.value,
            companyInfo_physicalAddress: this.companyInfo_physicalAddress.value,
            companyInfo_phone: this.companyInfo_phone.value,
            companyInfo_companyType: this.companyInfo_companyType.value,
            companyInfo_dateEstablished: this.companyInfo_dateEstablished.value,
        };
    }

    render() {
        // explicit class assigning based on validation
        let notValidClasses = {};

        if(
            typeof this.state.companyInfo_companyLegalNameVal === "undefined" ||
            this.state.companyInfo_companyLegalNameVal
        ) {
            notValidClasses.companyInfo_companyLegalNameCls = "form-control";
        } else {
            notValidClasses.companyInfo_companyLegalNameCls       = "is-invalid form-control";
            notValidClasses.companyInfo_companyLegalNameValGrpCls = "text-danger";
        }

        if(
            typeof this.state.companyInfo_companyWebsiteVal === "undefined" ||
            this.state.companyInfo_companyWebsiteVal
        ) {
            notValidClasses.companyInfo_companyWebsiteCls = "form-control";
        } else {
            notValidClasses.companyInfo_companyWebsiteCls       = "is-invalid form-control";
            notValidClasses.companyInfo_companyWebsiteValGrpCls = "text-danger";
        }

        if(
            typeof this.state.companyInfo_companyDescriptionVal === "undefined" ||
            this.state.companyInfo_companyDescriptionVal
        ) {
            notValidClasses.companyInfo_companyDescriptionCls = "form-control";
        } else {
            notValidClasses.companyInfo_companyDescriptionCls       = "is-invalid form-control";
            notValidClasses.companyInfo_companyDescriptionValGrpCls = "text-danger";
        }

        if(
            typeof this.state.companyInfo_industryVal === "undefined" ||
            this.state.companyInfo_industryVal
        ) {
            notValidClasses.companyInfo_industryCls = "form-control";
        } else {
            notValidClasses.companyInfo_industryCls       = "is-invalid form-control";
            notValidClasses.companyInfo_industryValGrpCls = "text-danger";
        }

        if(
            typeof this.state.companyInfo_noOfFullTimeEmployeeVal === "undefined" ||
            this.state.companyInfo_noOfFullTimeEmployeeVal
        ) {
            notValidClasses.companyInfo_noOfFullTimeEmployeeCls = "form-control";
        } else {
            notValidClasses.companyInfo_noOfFullTimeEmployeeCls       = "is-invalid form-control";
            notValidClasses.companyInfo_noOfFullTimeEmployeeValGrpCls = "text-danger";
        }

        if(
            typeof this.state.companyInfo_noOfPartTimeEmployeeVal === "undefined" ||
            this.state.companyInfo_noOfPartTimeEmployeeVal
        ) {
            notValidClasses.companyInfo_noOfPartTimeEmployeeCls = "form-control";
        } else {
            notValidClasses.companyInfo_noOfPartTimeEmployeeCls       = "is-invalid form-control";
            notValidClasses.companyInfo_noOfPartTimeEmployeeValGrpCls = "text-danger";
        }

        if(
            typeof this.state.companyInfo_doesAnyoneDoInternationTravelVal === "undefined" ||
            this.state.companyInfo_doesAnyoneDoInternationTravelVal
        ) {
            notValidClasses.companyInfo_doesAnyoneDoInternationTravelCls = "form-control";
        } else {
            notValidClasses.companyInfo_doesAnyoneDoInternationTravelCls       =
                "is-invalid form-control";
            notValidClasses.companyInfo_doesAnyoneDoInternationTravelValGrpCls = "text-danger";
        }

        if(
            typeof this.state.companyInfo_physicalAddressVal === "undefined" ||
            this.state.companyInfo_physicalAddressVal
        ) {
            notValidClasses.companyInfo_physicalAddressCls = "form-control";
        } else {
            notValidClasses.companyInfo_physicalAddressCls       = "is-invalid form-control";
            notValidClasses.companyInfo_physicalAddressValGrpCls = "text-danger";
        }

        if(typeof this.state.companyInfo_phoneVal === "undefined" || this.state.companyInfo_phoneVal) {
            notValidClasses.companyInfo_phoneCls = "form-control";
        } else {
            notValidClasses.companyInfo_phoneCls       = "is-invalid form-control";
            notValidClasses.companyInfo_phoneValGrpCls = "text-danger";
        }

        if(
            typeof this.state.companyInfo_companyTypeVal === "undefined" ||
            this.state.companyInfo_companyTypeVal
        ) {
            notValidClasses.companyInfo_companyTypeCls = "form-control";
        } else {
            notValidClasses.companyInfo_companyTypeCls       = "is-invalid form-control";
            notValidClasses.companyInfo_companyTypeValGrpCls = "text-danger";
        }

        if(
            typeof this.state.companyInfo_dateEstablishedVal === "undefined" ||
            this.state.companyInfo_dateEstablishedVal
        ) {
            notValidClasses.companyInfo_dateEstablishedCls = "form-control";
        } else {
            notValidClasses.companyInfo_dateEstablishedCls       = "is-invalid form-control";
            notValidClasses.companyInfo_dateEstablishedValGrpCls = "text-danger";
        }

        return (
            <div className="step step1 mt-5 ">
                <div className="row justify-content-md-center">
                    <div className="col col-lg-6">
                        <div className="">
                            <h4>Setup Your Company Information</h4>
                            <h6 className="card-subtitle">
                                To comply with regulation we collect this information to confirm
                                that you are a business that is legally incorporated.
                            </h6>
                            <form id="Form" className="form-horizontal mt-5">
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">
                                            Company's Legal Name
                                        </label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.companyInfo_companyLegalName = f;
                                                }}
                                                autoComplete="off"
                                                type="text"
                                                className={notValidClasses.companyInfo_companyLegalNameCls}
                                                required
                                                defaultValue={this.state.companyInfo_companyLegalName}
                                                onBlur={this.validationCheck}
                                            />
                                            <div
                                                className={notValidClasses.companyInfo_companyLegalNameValGrpCls}
                                            >
                                                {this.state.companyInfo_companyLegalNameValMsg}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">Company Website</label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.companyInfo_companyWebsite = f;
                                                }}
                                                autoComplete="off"
                                                type="text"
                                                className={notValidClasses.companyInfo_companyWebsiteCls}
                                                required
                                                defaultValue={this.state.companyInfo_companyWebsite}
                                                onBlur={this.validationCheck}
                                            />
                                            <div className={notValidClasses.companyInfo_companyWebsiteValGrpCls}>
                                                {this.state.companyInfo_companyWebsiteValMsg}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">Company Description</label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.companyInfo_companyDescription = f;
                                                }}
                                                autoComplete="off"
                                                type="text"
                                                className={notValidClasses.companyInfo_companyDescriptionCls}
                                                required
                                                defaultValue={this.state.companyInfo_companyDescription}
                                                onBlur={this.validationCheck}
                                            />
                                            <div
                                                className={notValidClasses.companyInfo_companyDescriptionValGrpCls}
                                            >
                                                {this.state.companyInfo_companyDescriptionValMsg}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">Industry</label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.companyInfo_industry = f;
                                                }}
                                                autoComplete="off"
                                                type="text"
                                                className={notValidClasses.companyInfo_industryCls}
                                                required
                                                defaultValue={this.state.companyInfo_industry}
                                                onBlur={this.validationCheck}
                                            />
                                            <div className={notValidClasses.companyInfo_industryValGrpCls}>
                                                {this.state.companyInfo_industryValMsg}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">
                                            Number of Full Time Employee
                                        </label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.companyInfo_noOfFullTimeEmployee = f;
                                                }}
                                                autoComplete="off"
                                                type="number"
                                                min="0"
                                                className={notValidClasses.companyInfo_noOfFullTimeEmployeeCls}
                                                required
                                                defaultValue={this.state.companyInfo_noOfFullTimeEmployee}
                                                onBlur={this.validationCheck}
                                            />
                                            <div
                                                className={
                                                    notValidClasses.companyInfo_noOfFullTimeEmployeeValGrpCls
                                                }
                                            >
                                                {this.state.companyInfo_noOfFullTimeEmployeeValMsg}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">
                                            Number of Part Time Employee
                                        </label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.companyInfo_noOfPartTimeEmployee = f;
                                                }}
                                                autoComplete="off"
                                                type="number"
                                                min="0"
                                                className={notValidClasses.companyInfo_noOfPartTimeEmployeeCls}
                                                required
                                                defaultValue={this.state.companyInfo_noOfPartTimeEmployee}
                                                onBlur={this.validationCheck}
                                            />
                                            <div
                                                className={
                                                    notValidClasses.companyInfo_noOfPartTimeEmployeeValGrpCls
                                                }
                                            >
                                                {this.state.companyInfo_noOfPartTimeEmployeeValMsg}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">
                                            Does Anyone Do Internation Travel
                                        </label>
                                        <div>
                                            <select
                                                ref={(e) => {
                                                    this.companyInfo_doesAnyoneDoInternationTravel = e;
                                                }}
                                                autoComplete="off"
                                                className={
                                                    notValidClasses.companyInfo_doesAnyoneDoInternationTravelCls
                                                }
                                                required
                                                defaultValue={this.state.companyInfo_doesAnyoneDoInternationTravel}
                                                onBlur={this.validationCheck}
                                            >
                                                <option value="">Please select</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                            <div
                                                className={
                                                    notValidClasses.companyInfo_doesAnyoneDoInternationTravelValGrpCls
                                                }
                                            >
                                                {this.state.companyInfo_doesAnyoneDoInternationTravelValMsg}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">Physical Address</label>
                                        <div>
                      <textarea
                          ref={(f) => {
                              this.companyInfo_physicalAddress = f;
                          }}
                          autoComplete="off"
                          rows="3"
                          className={notValidClasses.companyInfo_physicalAddressCls}
                          required
                          defaultValue={this.state.companyInfo_physicalAddress}
                          onBlur={this.validationCheck}
                      />
                                            <div className={notValidClasses.companyInfo_physicalAddressValGrpCls}>
                                                {this.state.companyInfo_physicalAddressValMsg}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">Phone</label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.companyInfo_phone = f;
                                                }}
                                                autoComplete="off"
                                                type="text"
                                                className={notValidClasses.companyInfo_phoneCls}
                                                required
                                                defaultValue={this.state.companyInfo_phone}
                                                onBlur={this.validationCheck}
                                            />
                                            <div className={notValidClasses.companyInfo_phoneValGrpCls}>
                                                {this.state.companyInfo_phoneValMsg}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label ">Company Type</label>
                                        <div>
                                            <select
                                                ref={(e) => {
                                                    this.companyInfo_companyType = e;
                                                }}
                                                autoComplete="off"
                                                className={notValidClasses.companyInfo_companyTypeCls}
                                                required
                                                defaultValue={this.state.companyInfo_companyType}
                                                onBlur={this.validationCheck}
                                            >
                                                <option value="">Please select</option>
                                                <option value="Corporation">Corporation</option>
                                                <option value="LLC">LLC</option>
                                                <option value="Partnership">Partnership</option>
                                            </select>
                                            <div className={notValidClasses.companyInfo_companyTypeValGrpCls}>
                                                {this.state.companyInfo_companyTypeValMsg}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group content form-block-holder col-sm-12 col-lg-6 col-md-6">
                                        <label className="control-label">Date Established</label>
                                        <div>
                                            <input
                                                ref={(f) => {
                                                    this.companyInfo_dateEstablished = f;
                                                }}
                                                autoComplete="off"
                                                type="date"
                                                className={notValidClasses.companyInfo_dateEstablishedCls}
                                                required
                                                defaultValue={this.state.companyInfo_dateEstablished}
                                                onBlur={this.validationCheck}
                                            />
                                            <div className={notValidClasses.companyInfo_dateEstablishedValGrpCls}>
                                                {this.state.companyInfo_dateEstablishedValMsg}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
