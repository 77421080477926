import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../../Provider/AuthContext";
import {useHistory} from "react-router-dom";
import api from "../../../../config/api";
import {Breadcrumb, BreadcrumbItem, Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import cardCategory from "../../../data/cardCategory.json";
import {toast} from "react-toastify";
import _ from "lodash";
import masterCard from "../../../../assets/images/master-card.png";

const cardTemplate = {
    name: '',
    budget_id: '',
    funding: '',
    limit: '',
    holder_id: '',
    category: ''
};

const AllBudgets = () => {
    const [applicationState]                    = useContext(AuthContext);
    const history                               = useHistory();
    const [cards, setCards]                     = useState([]);
    const [virtualCards, setVirtualCards]       = useState([]);
    const [tab, setTab]                         = useState("Wallet"); //Wallet/Cards
    const [view, setView]                       = useState("List");  //List/Blocks
    const [cardType, setCardType]               = useState("ALL"); //ALL/PHYSICAL/SUBSCRIPTION
    const [search, setSearch]                   = useState("");
    const [vSearch, setVSearch]                 = useState("");
    const [budgets, setBudgets]                 = useState([]);
    const [people, setPeople]                   = useState([]);
    const [flag, setFlag]                       = useState(false);
    const [card, setCard]                       = useState(cardTemplate);
    const [validated, setValidated]             = useState(false);
    const [availableAmount, setAvailableAmount] = useState(0);
    const [flag2, setFlag2]                     = useState(false);
    const [image, setImage]                     = useState('');
    const [selectedCard, setSelectedCard]       = useState({});

    const getCards = async () => {
        let {data} = await api.get('/api/cards');
        return data;
    }

    const getBudgets = async () => {
        let {data} = await api.get('/api/budgets');
        return data;
    }

    const getPeople = async () => {
        let {data} = await api.get('/api/people');
        return data;
    }

    const toggleFlag = () => setFlag(!flag);

    const toggleFlag2 = () => setFlag2(!flag2);

    useEffect(() => {
        getCards()
            .then(data => {
                setCards(data);
                let vCards = data.length > 0 ? data.filter(card => {
                    return card.category !== 'PHYSICAL';
                }) : [];
                setVirtualCards(vCards);
            })
            .catch();
    }, []);

    useEffect(() => {
        getBudgets()
            .then(data => {
                setBudgets(data);
            })
            .catch();
    }, []);

    useEffect(() => {
        getPeople()
            .then(data => {
                setPeople(data);
            })
            .catch();
    }, []);

    const searchCard = (card) => {
        return card.name.indexOf(search) !== -1 || card.holder_first_name.indexOf(search) !== -1 || card.holder_last_name.indexOf(search) !== -1 || card.budget.indexOf(search) !== -1;
    }

    const vSearchCard = (card) => {
        return card.name.indexOf(vSearch) !== -1 || card.holder_first_name.indexOf(vSearch) !== -1 || card.holder_last_name.indexOf(vSearch) !== -1 || card.budget.indexOf(vSearch) !== -1;
    }

    const calculateAvailableAmount = (id) => {
        let budget = _.find(budgets, {id: Number(id)});
        setAvailableAmount(budget && ((budget.availableAmount - (budget.spentAmount + budget.assignedAmount)) > 0) ? (budget.availableAmount - (budget.spentAmount + budget.assignedAmount)) : 0);
    }

    const cancel = () => {
        try {
            toggleFlag();
            setCard(cardTemplate);
            setValidated(false);
            setAvailableAmount(0);
        } catch(e) {
            console.log(e);
        }

    }

    const createCard = async () => {
        setValidated(true);
        if(card.name !== '' && card.budget_id !== '' && card.funding !== '' && card.limit !== '' &&
            Number(card.limit) > 0 && Number(card.limit) <= availableAmount && card.holder_id !== '' && card.category !== '') {
            try {
                await api.post('/api/cards', card);
                await toast.success('Card created');
                let data = await getCards();
                setCards(data);
                let vCards = data.length > 0 ? data.filter(card => {
                    return card.category !== 'PHYSICAL';
                }) : [];
                setVirtualCards(vCards);
                setAvailableAmount(0);
                cancel();
            } catch(e) {
                await toast.error(e.data.message);
            }
        }
    }

    const previewCard = (card) => {
        console.log(card);
        toggleFlag2();
        setSelectedCard(card);
    }

    return (
        <div className="mx-0">
            <div className="row">
                <div className="col-12">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <a href="/dashboard">{applicationState.companyName ? applicationState.companyName : 'Home'}</a>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            <a href="/cards">Cards</a>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </div>
            </div>

            <div className="card">
                <div className="card-header bg-white">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <h2>Cards</h2>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <button type={"button"} className="btn btn-success float-right" disabled={applicationState.role !== "ADMIN"} onClick={() => {
                                setFlag(true)
                            }}>Create New Card
                            </button>
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    <div className="row mb-2">
                        <div className="col">
                            <ul className="nav">
                                <a href="#" className={`nav-link ${tab === 'Wallet' ? 'active border-bottom-w-5' : 'text-muted'}`} onClick={() => setTab('Wallet')}>Your wallet</a>
                                <a href="#" className={`nav-link ${tab === 'Cards' ? 'active border-bottom-w-5' : 'text-muted'}`} onClick={() => setTab('Cards')}>All virtual cards</a>
                            </ul>
                        </div>
                    </div>

                    {
                        tab === 'Wallet' &&
                        <div>
                            <div className="row mb-2">
                                <div className="col-lg-3 col-md-4">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
											<span className="input-group-text bg-white border-right-0">
												<i className="fa fa-search"/>
											</span>
                                        </div>
                                        <input type="text" className="form-control border-left-0" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-8 mt-2 mt-lg-0 mt-md-0 text-lg-right text-md-right">
									<span className="border-right-only mr-2 pr-2">
										<span className="text-capitalize mr-2 d-none d-lg-inline d-md-inline">Card Type:</span>

										<div className="btn-group btn-group-sm">
											<button className={`btn btn-light ${cardType === 'ALL' && 'font-weight-bolder'}`} onClick={() => {
                                                setCardType('ALL')
                                            }}>ALL</button>
											<button className={`btn btn-light ${cardType === 'PHYSICAL' && 'font-weight-bolder'}`} onClick={() => {
                                                setCardType('PHYSICAL')
                                            }}>PHYSICAL</button>
											<button className={`btn btn-light ${cardType !== 'PHYSICAL' && 'font-weight-bolder'}`} onClick={() => {
                                                setCardType('VIRTUAL')
                                            }}>VIRTUAL</button>
										</div>
									</span>

                                    <span className="float-right">
										<span title={"Grid View"}>
											<FeatherIcon icon="grid" size={20} className={`pointer mr-2 ${view === 'Blocks' ? '' : 'text-muted'}`} onClick={() => setView('Blocks')}/>
										</span>
										<span title={"List View"}>
											<FeatherIcon icon="menu" className={`pointer ${view === 'List' ? '' : 'text-muted'}`} onClick={() => setView('List')}/>
										</span>
									</span>
                                </div>
                            </div>
                            <div>
                                {
                                    view === 'List' &&
                                    <div className="row">
                                        <div className="col">
                                            <div className="table-responsive">
                                                <table className={"table table-hover"}>
                                                    <thead>
                                                    <tr>
                                                        <th className="text-capitalize text-muted p-2">Name</th>
                                                        <th className="text-capitalize text-muted p-2">Card Owner</th>
                                                        <th className="text-capitalize text-muted p-2">Vendor</th>
                                                        <th className="text-capitalize text-muted p-2">Budget</th>
                                                        <th className="text-capitalize text-muted p-2">Last Charged On</th>
                                                        <th className="text-capitalize text-muted p-2">Type</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        cards.map((card, index) => {
                                                            return (
                                                                <tr key={`card-list-${index}`} onClick={() => previewCard(card)}
                                                                    className={`${(search === '' || searchCard(card)) && (cardType === 'ALL' || (cardType === 'PHYSICAL' && card.category === 'PHYSICAL') || (cardType !== 'PHYSICAL' && card.category !== 'PHYSICAL')) ? '' : 'd-none'}`}>
                                                                    <td className="p-2 pointer">{card.name}</td>
                                                                    <td className="p-2 pointer">{card.holder_first_name} {card.holder_last_name}</td>
                                                                    <td className="p-2 pointer">Awaiting use</td>
                                                                    <td className="p-2 pointer">{card.budget}</td>
                                                                    <td className="p-2 pointer"><Moment date={card.updated_on} format={"MMMM DD, YYYY"}/></td>
                                                                    <td className="p-2 pointer">{card.category}</td>
                                                                </tr>);
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    view === 'Blocks' &&
                                    <div className="row mt-2">
                                        {
                                            cards.map((card, index) => {
                                                return (<div key={`card-block-${index}`} onClick={() => previewCard(card)}
                                                             className={`${(search === '' || searchCard(card)) && (cardType === 'ALL' || (cardType === 'PHYSICAL' && card.category === 'PHYSICAL') || (cardType !== 'PHYSICAL' && card.category !== 'PHYSICAL')) ? 'col-lg-4 col-md-6 col-sm-12' : 'd-none'}`}>
                                                    <div className="card card-hover border border-rounded">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-6 text-muted">
                                                                    Name
                                                                </div>
                                                                <div className="col-lg-8 col-md-6 text-md-right">
                                                                    {card.name}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-6 text-muted">
                                                                    Owner
                                                                </div>
                                                                <div className="col-lg-8 col-md-6 text-md-right">
                                                                    {card.holder_first_name} {card.holder_last_name}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-6 text-muted">
                                                                    Budget
                                                                </div>
                                                                <div className="col-lg-8 col-md-6 text-md-right">
                                                                    {card.budget}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-6 text-muted">
                                                                    Vendor
                                                                </div>
                                                                <div className="col-lg-8 col-md-6 text-md-right">
                                                                    Awaiting use
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-6 text-muted">
                                                                    Last Charged On
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 text-md-right">
                                                                    <Moment date={card.updated_on} format={"MMMM DD, YYYY"}/>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-6 text-muted">
                                                                    Type
                                                                </div>
                                                                <div className="col-lg-8 col-md-6 text-md-right">
                                                                    {card.type === 'PHYSICAL' ? 'Physical' : 'Subscription'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>);
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    }

                    {
                        tab === 'Cards' &&
                        <div>
                            <div className="row mb-2">
                                <div className="col-lg-3 col-md-4">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
											<span className="input-group-text bg-white border-right-0">
												<i className="fa fa-search"/>
											</span>
                                        </div>
                                        <input type="text" className="form-control border-left-0" placeholder="Search" value={vSearch} onChange={(e) => setVSearch(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="table-responsive">
                                        <table className={"table table-hover"}>
                                            <thead>
                                            <tr>
                                                <th className="text-capitalize text-muted p-2">Name</th>
                                                <th className="text-capitalize text-muted p-2">Card Owner</th>
                                                <th className="text-capitalize text-muted p-2">Vendor</th>
                                                <th className="text-capitalize text-muted p-2">Budget</th>
                                                <th className="text-capitalize text-muted p-2">Last Charged On</th>
                                                <th className="text-capitalize text-muted p-2">Type</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                virtualCards.map((card, index) => {
                                                    return (
                                                        <tr key={`card-list-${index}`} onClick={() => previewCard(card)}
                                                            className={`${(vSearch === '' || vSearchCard(card)) ? '' : 'd-none'}`}>
                                                            <td className="p-2 pointer">{card.name}</td>
                                                            <td className="p-2 pointer">{card.holder_first_name} {card.holder_last_name}</td>
                                                            <td className="p-2 pointer">Awaiting use</td>
                                                            <td className="p-2 pointer">{card.budget}</td>
                                                            <td className="p-2 pointer"><Moment date={card.updated_on} format={"MMMM DD, YYYY"}/></td>
                                                            <td className="p-2 pointer">{card.category}</td>
                                                        </tr>);
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <Modal isOpen={flag} toggle={toggleFlag} modalClassName={'right'}>
                <ModalHeader toggle={toggleFlag}>Create card</ModalHeader>
                <ModalBody className="options-vertical overflow-auto pb-3">
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label className="text-capitalize font-weight-bolder text-muted">CARD NAME</label>
                                <input type="text" className={`form-control ${validated && !card.name ? 'is-invalid' : ''}`} id="card_name" placeholder="Untitled card" value={card.name}
                                       onChange={(e) => {
                                           setCard({...card, name: e.target.value})
                                       }}/>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="form-group mb-0">
                                <label className="text-capitalize font-weight-bolder">BUDGET SETTINGS</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label className="text-capitalize text-muted font-weight-bolder">BUDGET</label>
                                <select className={`form-control ${validated && !card.budget_id ? 'is-invalid' : ''}`} value={card.budget_id}
                                        onChange={(e) => {
                                            setCard({...card, budget_id: e.target.value});
                                            calculateAvailableAmount(e.target.value);
                                        }}>
                                    <option value={''}>Select a budget</option>
                                    {
                                        budgets.map((budget, index) => {
                                            return (<option key={`budget-${index}`} value={budget.id}>{budget.name}</option>)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label className="text-capitalize text-muted font-weight-bolder">CARD FUNDING</label>
                                <div className="form-check">
                                    <input className={`form-check-input ${validated && !card.funding ? 'is-invalid' : ''}`}
                                           type="radio" name="funding" id="funding1" value="BURNER" checked={card.funding === 'BURNER'}
                                           onChange={(e) => setCard({...card, funding: e.target.value})}/>
                                    <label className="form-check-label">
                                        Burner
                                    </label>
                                </div>
                                <small className="ml-3 pl-1 text-muted">Expires in 7 days</small>
                                <div className="form-check">
                                    <input className={`form-check-input ${validated && !card.funding ? 'is-invalid' : ''}`}
                                           type="radio" name="funding" id="funding2" value="SUBSCRIPTION" checked={card.funding === 'SUBSCRIPTION'}
                                           onChange={(e) => setCard({...card, funding: e.target.value})}/>
                                    <label className="form-check-label">
                                        Subscription
                                    </label>
                                </div>
                                <small className="ml-3 pl-1 text-muted">Resets card limit every month</small>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-6">
                                        <label className="text-capitalize text-muted font-weight-bolder">MONTHLY CARD LIMIT</label>
                                    </div>
                                    <div className="col-6">
                                        <label className="text-capitalize text-muted font-weight-bolder">
                                            <span className="text-success">${availableAmount}</span> AVAILABLE IN BUDGET
                                        </label>
                                    </div>
                                </div>
                                <NumberFormat thousandSeparator={false}
                                              prefix={"$"}
                                              className={`form-control ${validated && !(card.limit !== '' && card.limit > 0 && card.limit <= availableAmount) ? 'is-invalid' : ''}`}
                                              allowEmptyFormatting={false}
                                              allowNegative={false}
                                              placeholder={"$0.00"}
                                              value={card.limit}
                                              onChange={(e) => setCard({...card, limit: e.target.value.slice(1)})}/>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="form-group mb-0">
                                <label className="text-capitalize font-weight-bolder">SECURITY</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group mb-0">
                                <label className="text-capitalize text-muted font-weight-bolder">CARD HOLDER</label>
                                <select className={`form-control ${validated && !card.holder_id ? 'is-invalid' : ''}`}
                                        value={card.holder_id} onChange={(e) => setCard({...card, holder_id: e.target.value})}>
                                    <option value="">Select person</option>
                                    {
                                        people.map((person, index) => {
                                            return (<option key={`person-${index}`} value={person.id}>
                                                {person.primaryContact_firstName} {person.primaryContact_lastName}
                                            </option>)
                                        })
                                    }
                                </select>
                                <small className="text-muted">Choose who should see and manage this card number. This can't be changed after creation.</small>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="form-group mb-0">
                                <label className="text-capitalize font-weight-bolder">PREFILL TRANSACTIONS ON THIS CARD</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group mb-0">
                                <small className="text-muted">Fields that you select here will be applied to all transactions made on this card</small>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="form-group mb-0">
                                <label className="text-capitalize font-weight-bolder">REQUIRED FOR GENERAL (1)</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group mb-0">
                                <label className="text-capitalize text-muted font-weight-bolder">CATEGORY <span className="text-danger">*</span></label>
                                <select className={`form-control ${validated && !card.category ? 'is-invalid' : ''}`}
                                        value={card.category} onChange={(e) => setCard({...card, category: e.target.value})}>
                                    <option value="">Select Category</option>
                                    {
                                        cardCategory.map((category, index) => {
                                            return (<option key={`person-${index}`} value={category.value}>{category.name}</option>)
                                        })
                                    }
                                </select>
                                <small className="text-muted">Choose who should see and manage this card number. This can't be changed after creation.</small>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="w-100">
                        <div className="d-inline-block w-50">
                            <Button color="secondary" onClick={() => cancel()}>Close</Button>
                        </div>
                        <div className="d-inline-block text-right w-50">
                            <Button color="primary" onClick={() => createCard()}>Create Card</Button>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>

            <Modal isOpen={flag2} toggle={toggleFlag2} modalClassName={'right'}>
                <ModalHeader toggle={toggleFlag2}>Virtual card</ModalHeader>
                <ModalBody className="options-vertical overflow-auto pb-3">
                    <div className="vcard">
                        <div className="vcard-content">
                            <div className="row">
                                <div className="col-3 ml-0" style={{borderTopRightRadius: '10px'}}/>
                                <div className="col-6 text-center bg-white font-weight-bold" style={{borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'}}>BUDGET CARD</div>
                                <div className="col-3 mr-0" style={{borderTopLeftRadius: '10px'}}/>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <small className="text-white ml-3">{selectedCard.budget}</small>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h2 className="ml-3 text-white mb-0">${selectedCard.limit}</h2>
                                    <small className="text-white ml-3">available to spend</small><br/>
                                    <small className="text-white ml-3">${selectedCard.limit} {selectedCard.funding === 'BURNER' ? 'in 7 days' : 'monthly'}</small>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12 font-weight-bold text-white text-left d-flex justify-content-between">
                                    {applicationState.id === selectedCard.holder_id ? <span className="pl-3 font-20">{selectedCard.number ? selectedCard.number.slice(0, 4) : ''} </span> : <span className="pl-3 font-20">XXXX</span>}
                                    {applicationState.id === selectedCard.holder_id ? <span className='font-20'>{selectedCard.number ? selectedCard.number.slice(4, 8) : ''}</span> : <span className="pl-3 font-20">XXXX</span>}
                                    {applicationState.id === selectedCard.holder_id ? <span className="font-20">{selectedCard.number ? selectedCard.number.slice(8, 12) : ''}</span> : <span className="pl-3 font-20">XXXX</span>}
                                    <span className="pr-3 font-20">{selectedCard.number ? selectedCard.number.slice(12, 16) : ''}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8 text-white">
                                    <small className="pl-3 pr-1">VALID TILL</small>
                                    <span className="font-20 font-weight-bold">{selectedCard.expiry}</span>
                                </div>
                                <div className="col-4 font-20 text-white font-weight-bold">{applicationState.id === selectedCard.holder_id ? selectedCard.cvv : "XXX"}</div>
                            </div>
                            <div className="row mt-1">
                                <div className="col-8 font-16 text-white">
                                    <span className="pl-3">{selectedCard.holder_first_name} {selectedCard.holder_last_name}</span>
                                </div>
                                <div className="col-4 text-right">
                                    <img src={masterCard} alt="" width="40px" className="mr-3"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => toggleFlag2()}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default AllBudgets;
