import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import AuthContext from "../../../Provider/AuthContext";
import importCsv from "../../../../assets/templates/import.csv";
import "../../../../assets/custom/drag-and-drop.css";
import api from "../../../../config/api";
import {toast} from "react-toastify";

const AddPerson = () => {
	const [applicationState]  = useContext(AuthContext);
	const [status, setStatus] = useState('IDLE');
	const [file, setFile]     = useState();
	const [users, setUsers]   = useState([]);
	const history             = useHistory();

	const handleFileChange = (e) => {
		if(e.target.files && e.target.files.length > 0) {
			setFile(e.target.files[0]);
		}
	}

	useEffect(() => {
		if(file) {
			const reader  = new FileReader();
			reader.onload = function(e) {
				const rows   = e.target.result.split("\n");
				const _users = [];
				for(let i = 1; i < rows.length - 1; i++) {
					const cells = rows[i].split(",");
					_users.push({
						first_name: cells[0] ? cells[0].trim() : null,
						last_name: cells[1] ? cells[1].trim() : null,
						email: cells[2] ? cells[2].trim() : null,
						phone: cells[3] ? cells[3].trim() : null,
						date_of_birth: cells[4] ? cells[4].trim() : null,
						permission: cells[5] ? cells[5].trim() : null,
						send_invite: cells[6] ? cells[6].trim() : null
					});
				}
				setStatus('ACTIVE');
				setUsers(_users);
			}
			reader.readAsText(file);
		}
	}, [file]);

	const submit = async () => {
		try {
			let response = await api.post(`/api/people/import`, users);
			await history.push("/people");
			await toast.success(response.data.message);
		} catch(e) {
			console.dir(e.data);
			if(e.response && e.response.status === 500) {
				toast.error(e.response.data.message);
			}
			toast.error(e.message);
		}
	};

	const onCancel = (e) => {
		history.push("/people");
	}

	return (
	<div className="step step1 mt-5">
		<div className="row justify-content-md-center">
			<div className="col col-lg-8 border-bottom">
				<div className="row mx-2">
					<div className="col-lg-8 col-md-8 col-sm-12">
						<h1>Import people</h1>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-12">
						<button type={"button"} className="btn btn-success float-right" disabled={applicationState.role !== "ADMIN"} onClick={submit} disabled={status === 'IDLE'}>Submit</button>
						<button type={"button"} className="btn btn-light mr-2 float-right" onClick={onCancel}>Cancel</button>
					</div>
				</div>
			</div>
		</div>
		<div className="row justify-content-md-center mx-2 mt-3">
			<div className="col-lg-4 col-md-4 col-sm-12">
				<span className={"ml-2"}>Adding your employees to Quarkie in bulk is easy</span>
				<ol className={"pl-4 mt-4"}>
					<li className={"mb-3"}>{
						applicationState.role !== "ADMIN"
							? <a href="" onClick={ (event) => event.preventDefault() }>Download this CSV template</a>
							: <a href={importCsv} download="import.csv">Download this CSV template</a> }to get started
					</li>
					<li className={"mb-3"}>Add your employees to the template. Don't remove the first line.</li>
					<li className={"mb-3"}>Upload the template to Quarkie. Please note that the file must be in CSV format and contain no more than 150 rows.</li>
					<li className={"mb-3"}>Once uploaded, we'll email you to let you know if there were any errors in you file.</li>
				</ol>
			</div>
			<div className="col-lg-4 col-md-4 col-sm-12">
				<div className="form-group files color text-center">
					<input type="file" className="form-control" title=" " accept=".csv"
					       style={{"height": "250px", "color": "transparent"}} disabled={applicationState.role !== "ADMIN"} onChange={handleFileChange}/>
					{file && <label id="dndlabel">{file.name}</label>}
				</div>
			</div>
		</div>
	</div>
	);
};

export default AddPerson;
