import React, {useState, useEffect, Suspense, useContext} from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import Header from "./layout-components/header/Header";
import Sidebar from "./layout-components/sidebar/Sidebar";
import Footer from "./layout-components/footer/Footer";
import Spinner from "./layout-components/spinner/Spinner";
import menuConfig from "../../config/menuConfig.json";
import AuthContext from "../Provider/AuthContext";

export default (props) => {
	const [inputState, callbacks] = useContext(AuthContext);
	const [width, setWidth]       = useState(window.innerWidth);

	useEffect(() => {
		const updateDimensions = () => {
			let element = document.getElementById("main-wrapper");
			if(!element){
				return;
			}

			setWidth(window.innerWidth);
			switch(inputState.activeSidebarType) {
				case "full":
				case "iconbar":
					if(width < 1170) {
						element.setAttribute("data-sidebartype", "mini-sidebar");
						element.classList.add("mini-sidebar");
					} else {
						element.setAttribute(
						"data-sidebartype",
						inputState.activeSidebarType
						);
						element.classList.remove("mini-sidebar");
					}
					break;

				case "overlay":
					if(width < 767) {
						element.setAttribute("data-sidebartype", "mini-sidebar");
					} else {
						element.setAttribute("data-sidebartype", inputState.activeSidebarType);
					}
					break;

				default:
			}
		};

		if(document.readyState === "complete") {
			updateDimensions();
		}

		window.addEventListener("load", updateDimensions.bind(null));
		window.addEventListener("resize", updateDimensions.bind(null));

		return () => {
			window.removeEventListener("load", updateDimensions.bind(null));
			window.removeEventListener("resize", updateDimensions.bind(null));
		};
	}, [inputState.activeSidebarType, width]);

	return (
	<div id="main-wrapper"
	     dir={inputState.activeDir}
	     data-theme={inputState.activeTheme}
	     data-layout={inputState.activeThemeLayout}
	     data-sidebartype={inputState.activeSidebarType}
	     data-sidebar-position={inputState.activeSidebarPos}
	     data-header-position={inputState.activeHeaderPos}
	     data-boxed-layout={inputState.activeLayout}>
		<Header/>
		<Sidebar {...props} routes={menuConfig}/>
		<div className="page-wrapper d-block">
			<div className="page-content container-fluid px-0 px-lg-3 px-md-2 px-sm-1 pt-0">
				<Suspense fallback={<Spinner/>}>
					<Switch>
						<Route component={props.view}/>
					</Switch>
				</Suspense>
			</div>
			<Footer/>
		</div>
	</div>
	);
};
