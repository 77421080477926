import React, {useContext, useEffect, useState} from 'react';
import api from "../../../../config/api";
import {formatDate} from "../../../utils/helper";
import AuthContext from "../../../Provider/AuthContext";
import {Breadcrumb, BreadcrumbItem, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import UserAvatar from "react-user-avatar";
import SendFundModal from "./SendFundModal";
import Moment from "react-moment";

const BudgetDetail = (props) => {
    const budgetId            = props.match.params.id;
    const [applicationState]  = useContext(AuthContext);
    const [budget, setBudget] = useState({});
    const [transactions, setTransactions] = useState([]);

    const fetchBudgetById = async (id) => {
        let res                 = await api.get(`/api/budgets/${id}`);
        res.data.expirationDate = res.data ? res.data.expirationDate ? formatDate(res.data.expirationDate) : null : null;
        return res.data;
    }

    const fetchBudgetTransactions = async (id) => {
        let res                 = await api.get(`/api/budgets/${id}/transactions`);
        res.data.createdAt = res.data ? res.data.createdAt ? formatDate(res.data.createdAt) : null : null;
        return res.data;
    }

    // useEffect(() => {
    //     fetchBudgetById(budgetId)
    //         .then(res => {
    //             setBudget(res);
    //             fetchBudgetTransactions(budgetId)
    //                 .then(response => {
    //                     // setTransactions(response);
    //                     console.log(response);
    //                 })
    //         })
    //         .catch()
    // }, []);

    useEffect(() => {
        fetchBudgetTransactions(budgetId)
            .then(response => {
                setTransactions(response);
            })
            .catch()

    }, []);

    useEffect(() => {
        fetchBudgetById(budgetId)
            .then(res => {
                setBudget(res);
            })
            .catch()
    }, []);

    useEffect(() => {
        fetchBudgetTransactions(budgetId)
            .then(response => {
                setTransactions(response);
            })
            .catch()

    }, [budgetId]);

    useEffect(() => {
        fetchBudgetById(budgetId)
            .then(res => {
                setBudget(res);
            })
            .catch()
    }, [budgetId]);


    const onSendFund     = (e) => {
        e.preventDefault();
        setOwnerModal(!ownerModal);
    }
    const onSettingClick = (e) => {
        e.preventDefault();
    }

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    const [activeSubTab, setActiveSubTab] = useState('1');

    const toggleSubTab = tab => {
        if(activeSubTab !== tab) setActiveSubTab(tab);
    }

    const [ownerModal, setOwnerModal] = useState(false);

    const onClose = (modalOpen) => {
        setOwnerModal(modalOpen);
        window.location.reload();
    }

    return (
        <div>
            <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-12">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <a href="/dashboard">{applicationState.companyName ? applicationState.companyName : 'Home'}</a>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <a href="/budgets">Budgets</a>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            <a href={`/budgets/` + budget.id}>{budget.name}</a>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </div>
            </div>
            <div className="card">
                <div className="card-header bg-white">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <div className="row">
                                <div className="col-12">
                                    <h1>{budget.name}</h1>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                            <span
                                className="badge badge-inverse">{budget.budgetCycle === 'ONETIME' ? 'Expires : ' + [budget.expirationDate ? budget.expirationDate : ''] : [budget.budgetCycle === 'RECURRING' ? 'Recurring' : '']}</span>

                                    <span className="badge badge-inverse ml-3">{budget.budgetCycle === 'ONETIME' ? 'ONE TIME' : [budget.budgetCycle === 'RECURRING' ? 'RECURRING' : '']}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <button type={"button"} className="btn btn-success mr-2 float-right" disabled={applicationState.role !== "ADMIN"} onClick={onSendFund}>Send Funds</button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: activeTab === '1'})}
                                        onClick={() => {
                                            toggle('1');
                                        }}
                                    >
                                        Administration
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink disabled={true}
                                        className={classnames({active: activeTab === '2'})}
                                        onClick={() => {
                                            toggle('2');
                                        }}
                                    >
                                        Spend Limits
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink disabled={true}
                                        className={classnames({active: activeTab === '3'})}
                                        onClick={() => {
                                            toggle('3');
                                        }}
                                    >
                                        Recurring Funds
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent className="p-4" activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="card card-hover">
                                                <div className="card-header bg-white">
                                                    <div className="row ml-2">
                                                        <h3>Budget Snapshot</h3>
                                                    </div>
                                                    <div
                                                        className="row ml-2">Budget Limit: ${budget.availableAmount}&nbsp;<a style={{"textDecoration": "underline"}} href="" onClick={ (event) => event.preventDefault() }>Edit</a></div>
                                                </div>
                                                <div className="card-body">
                                                    {budget.spentAmount < budget.assignedAmount &&
                                                    <div className="progress active my-2" style={{height: "20px"}}>
                                                        <div className="progress-bar bg-success" role="progressbar" style={{width: ((budget.spentAmount / budget.availableAmount) * 100) + "%"}}
                                                             aria-valuenow={(budget.spentAmount / budget.availableAmount) * 100} aria-valuemin="0" aria-valuemax="100">
                                                        </div>
                                                        <div className="progress-bar bg-secondary" role="progressbar" style={{width: ((budget.assignedAmount / budget.availableAmount) * 100) + "%"}}
                                                             aria-valuenow={(budget.assignedAmount / budget.availableAmount) * 100} aria-valuemin="0" aria-valuemax="100">
                                                        </div>
                                                        <div className="progress-bar bg-cyan" role="progressbar"
                                                             style={{width: (((budget.availableAmount - (budget.spentAmount + budget.assignedAmount)) / budget.availableAmount) * 100) + "%"}}
                                                             aria-valuenow={((budget.availableAmount - (budget.spentAmount + budget.assignedAmount)) / budget.availableAmount) * 100} aria-valuemin="0" aria-valuemax="100">
                                                        </div>
                                                    </div>
                                                    }
                                                    {budget.spentAmount >= budget.assignedAmount &&
                                                    <div className="progress active my-2" style={{height: "20px"}}>
                                                        <div className="progress-bar bg-secondary" role="progressbar" style={{width: ((budget.assignedAmount / budget.availableAmount) * 100) + "%"}}
                                                             aria-valuenow={(budget.assignedAmount / budget.availableAmount) * 100} aria-valuemin="0" aria-valuemax="100">
                                                        </div>
                                                        <div className="progress-bar bg-success" role="progressbar" style={{width: ((budget.spentAmount / budget.availableAmount) * 100) + "%"}}
                                                             aria-valuenow={(budget.spentAmount / budget.availableAmount) * 100} aria-valuemin="0" aria-valuemax="100">
                                                        </div>
                                                        <div className="progress-bar bg-cyan" role="progressbar"
                                                             style={{width: (((budget.availableAmount - (budget.spentAmount + budget.assignedAmount)) / budget.availableAmount) * 100) + "%"}}
                                                             aria-valuenow={((budget.availableAmount - (budget.spentAmount + budget.assignedAmount)) / budget.availableAmount) * 100} aria-valuemin="0"
                                                             aria-valuemax="100">
                                                        </div>
                                                    </div>
                                                    }
                                                </div>
                                                <div className="card-footer">
                                                    <div className="row">
                                                        <div className="col">
                                                            <i className="fas fa-circle text-success"></i> Spent
                                                        </div>
                                                        <div className="col">
                                                            <h5 className="text-success text-right">${budget.spentAmount}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <i className="fas fa-circle text-secondary"></i> Assigned
                                                        </div>
                                                        <div className="col">
                                                            <h5 className="text-secondary text-right">${budget.assignedAmount}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <i className="fas fa-circle text-cyan"></i> Available
                                                        </div>
                                                        <div className="col">
                                                            <h4 className="text-cyan text-right">${budget.availableAmount - (budget.spentAmount + budget.assignedAmount)}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="card card-hover">
                                                <div className="card-header bg-white">
                                                    <div className="row ml-2">
                                                        <h3>Roster</h3>
                                                    </div>
                                                    <div
                                                        className="row ml-2"><a style={{"textDecoration": "underline"}} href="" onClick={ (event) => event.preventDefault() }>View/edit roster</a>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row mx-2">
                                                        <div className="col-6">
                                                            <div className="row text-secondary">
                                                                Owners ({budget && budget.owners && budget.owners.length > 0 ? budget.owners.length : 0})
                                                            </div>
                                                            {budget && budget.owners && budget.owners.length > 0 && budget.owners.map(owner => {
                                                                return (
                                                                    <div className="row" key={owner.id}>
                                                                        <UserAvatar size="30" className={"text-white font-weight-bold float-left"} width={20}
                                                                                    name={owner.primaryContact_firstName + " " + owner.primaryContact_lastName}/>

                                                                        <h6 className={"d-inline float-left ml-2 mt-2"}>{owner.primaryContact_firstName} {owner.primaryContact_lastName}</h6>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>

                                                        <div className="col-6">
                                                            <div className="row text-secondary">
                                                                Members ({budget && budget.members && budget.members.length > 0 ? budget.members.length : 0})
                                                            </div>
                                                            {budget && budget.members && budget.members.length > 0 ?
                                                                budget.members.map(member => {
                                                                    return (
                                                                        <div className="row" key={member.id}>
                                                                            <UserAvatar size="30" className={"text-white font-weight-bold float-left"} width={20}
                                                                                        name={member.primaryContact_firstName + " " + member.primaryContact_lastName}/>

                                                                            <h6 className={"d-inline float-left ml-2 mt-2"}>{member.primaryContact_firstName} {member.primaryContact_lastName}</h6>
                                                                        </div>
                                                                    )
                                                                })
                                                                :
                                                                <div
                                                                    className="row"><a style={{"textDecoration": "underline"}} href="">Add members</a>
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    tab 2
                                </TabPane>
                                <TabPane tabId="3">
                                    tab 3
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                </div>
                {activeTab === '1' &&
                <div className="card-footer">

                    <div className="row">
                        <div className="col-12">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: activeSubTab === '1'})}
                                        onClick={() => {
                                            toggleSubTab('1');
                                        }}
                                    >
                                        Transactions
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink disabled={true}
                                        className={classnames({active: activeSubTab === '2'})}
                                        onClick={() => {
                                            toggleSubTab('2');
                                        }}
                                    >
                                        Invoices
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent className="p-4" activeTab={activeSubTab}>
                                <TabPane tabId="1">
                                    {/*<div className="row">*/}
                                    {/*    <div className="col-lg-3 col-md-3 col-sm-12 form-group">*/}
                                    {/*        <div className="input-group">*/}
                                    {/*            <input className="form-control py-2 border-right-0 border" type="search" placeholder="Search here..." id="example-search-input"/>*/}
                                    {/*            <span className="input-group-append">*/}
                                    {/*                <div className="input-group-text bg-transparent form-control"><i className="fa fa-search"></i></div>*/}
                                    {/*            </span>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    <div className="row">
                                        <div className="col">
                                            <div className="table-responsive">
                                                <table className={"table table-hover"}>
                                                    <thead>
                                                    <tr>
                                                        <th className="text-capitalize text-muted p-2">Id</th>
                                                        <th className="text-capitalize text-muted p-2">Event</th>
                                                        <th className="text-capitalize text-muted p-2">User</th>
                                                        <th className="text-capitalize text-muted p-2">Budget</th>
                                                        <th className="text-capitalize text-muted p-2">Amount</th>
                                                        <th className="text-capitalize text-muted p-2">Note</th>
                                                        <th className="text-capitalize text-muted p-2">Created At</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {   transactions.length > 0 &&
                                                        transactions.map((transaction, index) => {
                                                            return (
                                                                <tr key={`card-list-${index}`}>
                                                                    <td className="p-2 pointer">{transaction.id}</td>
                                                                    <td className="p-2 pointer">{transaction.event}</td>
                                                                    <td className="p-2 pointer">{transaction.primaryContact_firstName} {transaction.primaryContact_lastName}</td>
                                                                    <td className="p-2 pointer">{transaction.name}</td>
                                                                    <td className="p-2 pointer">{transaction.amount}</td>
                                                                    <td className="p-2 pointer">{transaction.note}</td>
                                                                    <td className="p-2 pointer"><Moment date={transaction.createdAt} format={"MMMM DD, YYYY"}/></td>
                                                                </tr>);
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </TabPane>
                                <TabPane tabId="2">
                                    sub tab 2
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                </div>}
            </div>
            {budget.id && <SendFundModal isOpen={ownerModal} onClose={onClose} budgetId={budget.id}/> }
        </div>
    );
}

export default BudgetDetail;
